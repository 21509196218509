import { $Enums } from "@prisma/client";

export interface Tag {
  id?: number;
  order?: number;

  name: string;
  type: string;
  slug: string;
  metadata?: object;
}

export interface VariantOption {
  id?: number;
  order?: number;
  name: string;
  type: string;
  slug: string;
  metadata?: object;
}

export interface ProductVariant {
  id?: number;
  name: string;
  product_id: string | number;
  size?: VariantOption;
  rrp?: number;
  color?: VariantOption;
  color_id?: number;
  created_at?: string | EpochTimeStamp;
  sku?: string;
  stock?: number;
  product?: BaseProduct; // for use when pulling in orders
}

export interface BaseProduct {
  id?: number;
  name: string;
  created_at?: string | EpochTimeStamp;
  updated_at?: string | EpochTimeStamp;
  published_at?: string | EpochTimeStamp;
  description?: string;
  images?: null | string[];
  product_reference?: string;
  slug?: string;
  accordion1_title?: string;
  accordion1?: string;
  accordion2_title?: string;
  accordion2?: string;
  accordion3_title?: string;
  accordion3?: string;
  rrp?: number;
  order: number;
  price?: number;
}

export interface Product extends BaseProduct {
  category?: string;
  product_variants?: ProductVariant[];
  product_tags?: {
    tags: Tag;
  }[];
  tags?: any; // this is only used for the form, should probably be updated to product_tags at some point
  // Enriched Data (not coming from backend)
  filter_tags?: string[];
  totalAvailability?: number;
  brand?: string;
}

export interface CartItem {
  uid: string;
  selected_variant: ProductVariant;
  product: Product;
  keptItem?: boolean;
  unavailable?: boolean;
}

export enum DeliveryProviderOptions {
  manual = "manual",
  royal_mail = "royal_mail",
}

export interface Order {
  delivery_date_time: string | Date;
  user_id: string | number;
  id?: number;
  created_at?: string;
  status: OrderStatusTypes;
  delivery_status?: DeliveryStatusTypes;
  return_date_time?: string | Date;
  subscription_type: SubscriptionTypes;
  internal_note?: string;
  stripe_subscription_id?: string;
  checkout_id?: string;
  order_product_variants?: { product_variant_id: number }[];
  delivery_provider?: DeliveryProviderOptions;
  delivery_tracking_number?: string;
}

export interface OrderProductVariant {
  id?: number;
  order_id?: number;
  product_variant_id?: number;
  created_at?: string;
}

export interface PartialOrderProductItemData {
  id?: number;
  partial_order?: {
    date: Date;
    status: $Enums.OrderStatus;
  };
  partial_return?: {
    date: Date;
    status: $Enums.OrderStatus;
  };
  partial_order_id?: number;
  partial_return_id?: number;
  product_item_id?: number;
  product_variant?: ProductVariant;
  user_id: string;
}

export interface OrderProductData {
  id?: number;
  order?: { id: number; user_id?: string };
  product_variant_id?: number;
  created_at?: string;
  product_variant?: ProductVariant;
}

export interface SizeOption {
  size?: VariantOption;
  stock?: number;
}

export interface GiftingOrder {
  status?: string;
  delivery_date?: string;
  delivery_status?: string; //tbc,
  title_sender?: string;
  first_name_sender?: string;
  last_name_sender?: string;
  email_sender?: string;
  phone_sender?: string;
  title_recipient?: string;
  first_name_recipient?: string;
  last_name_recipient?: string;
  email_recipient?: string;
  phone_recipient?: string;
  message?: string;
  coupon_id?: string;
  coupon_value?: string;
}

export enum TagTypes {
  BRAND = "brand",
  CATEGORY = "category",
  CONCEPT = "concept",
  MATERIAL = "material",
  PATTERN = "pattern",
  SEASON = "season",
  SLEEVE = "sleeve_length",
  TAG = "tag",
  TYPE = "type",
  FIT = "fit",
}

export enum VariantOptionTypes {
  COLOR = "Color",
  SIZE = "Size",
}

export enum OrderStatusTypes {
  PLACED_PAYMENT_PENDING = "ORDER_PLACED_PAYMENT_PENDING",
  PLACED_PAYMENT_CANCELLED = "ORDER_PLACED_PAYMENT_CANCELLED",
  PLACED = "ORDER_PLACED",
  ACTIVE = "ORDER_ACTIVE",
  FINALISED = "ORDER_FINALISED",
  PROBLEM = "ORDER_PROBLEM",
}

export enum DeliveryStatusTypes {
  REQUESTED = "REQUESTED",
  CONFIRMED = "CONFIRMED",
  RETURNREQUESTED = "RETURN_REQUESTED",
  RETURNCONFIRMED = "RETURN_CONFIRMED",
  RETURNDELIVERED = "RETURN_DELIVERED",
}

export enum SubscriptionTypes {
  A = "Subscription A",
  B = "Subscription B",
  C = "Subscription C",
}

export enum KlaviyoListCodes {
  NEWSLETTER = "VCgLyM",
  WAITLIST = "VUsE5S",
  SUBSCRIBER = "XJRF5g",
}
