import React, { ReactNode, useEffect } from "react";
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import classNames from "classnames";

import { FormData, SimpleInputProps } from "./types";

import roundStyles from "./roundStyles.module.css";
import squareStyles from "./squareStyles.module.css";
import ICONS from "../constants/icons";

//* Example usage: define the object for the data prop and place into <SimpleInput data={formData} /> component:
/*
e.g.
const formData = {
  inputNames: { username: "username", password: "password" },
  placeholderText: {
    username: "Input your password",
    password: "Input your username",
  },
  inputTypes: {
    password: "password",
    username: "text",
  },
  initialValues: {
    username: "",
    password: "",
  },
  ------------declare these below functions in the component in which you place <SimpleInput />, *OR* import them as constants

  handleSubmit: handleSubmit,
  submitButton: submitButton,
  validationSchema: validationSchema, | VALIDATION.YOURCONSTANTNAME
  requiredFieldsLabel: false
};
*/

const ErrorCallback = ({ errors, onFailedValidation }) => {
  useEffect(() => {
    if (typeof onFailedValidation === "function") {
      onFailedValidation(errors);
    }
  }, [errors, onFailedValidation]);

  return <></>;
};

const SimpleInput = ({
  children,
  data,
  inputStyle = "round",
  requiredFieldsLabel = false,
  onChange,
  onFocus,
  onFailedValidation,
  reset,
  discountCodeNote,
  ...extraProps
}: FormData): JSX.Element => {
  // Applies round or square (inputs) style sheet depending on the form type given in inputStyle
  const styles = {
    round: roundStyles,
    square: squareStyles,
  };

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      if (onChange) {
        onChange(values);
      }
    }, [values]);
    return null;
  };

  return (
    <>
      <Formik
        validationSchema={data.validationSchema}
        initialValues={data.initialValues}
        onSubmit={(values: any, { resetForm, setSubmitting }) => {
          setTimeout(() => {
            data.handleSubmit(values, null);
            reset && resetForm();
            setSubmitting(false);
          }, 400);
        }}
        innerRef={data?.innerRef}
      >
        {({
          isSubmitting,
          submitCount,
          errors,
          touched,
          values,
          isValid,
          resetForm,
          setFieldValue,
        }: FormikProps<FieldProps>) => {
          const resetValue = (fieldName) => {
            setFieldValue(fieldName, "");
          };

          return (
            <Form
              className={classNames(styles[inputStyle].inputContainer)}
              {...extraProps}
            >
              <ErrorCallback
                errors={errors}
                onFailedValidation={onFailedValidation}
              />
              {Object.keys(data.inputNames).map((itemName, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className={classNames(styles[inputStyle].singleField, {
                        [styles[inputStyle].singleFieldHalfWidth]:
                          data?.inputWidth &&
                          data?.inputWidth[itemName] === "50%",
                        [styles[inputStyle].checkbox]:
                          data.inputTypes[itemName] === "checkbox",
                      })}
                    >
                      {data.inputTypes[itemName] === "autoselect" ? (
                        <>
                          <label>{data.placeholderText[itemName]}</label>
                          <Field
                            className={styles[inputStyle].fieldInput}
                            as={
                              data.inputTypes[itemName] === "autoselect" &&
                              "select"
                            }
                            name={data.inputNames[itemName]}
                          >
                            {({
                              field: { name, value, onChange, onBlur },
                              form: {
                                touched,
                                errors,
                                setFieldValue,
                                submitForm,
                              }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              const update = (newVal) => {
                                // Update Locally
                                setFieldValue(name, newVal.target.value);
                                submitForm();
                              };

                              return (
                                <select onChange={update}>
                                  <option style={{ display: "none" }}>
                                    Select
                                  </option>
                                  {data.inputOptions[itemName].map(
                                    (item: string) => {
                                      return (
                                        <option
                                          key={`${index}+${item}`}
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              );
                            }}
                          </Field>
                        </>
                      ) : data.inputTypes[itemName] === "hidden" ? (
                        <Field
                          type="hidden"
                          name={data.inputNames[itemName]}
                          defaultValue={data.initialValues[itemName]}
                          value={data.initialValues[itemName]}
                        />
                      ) : data.inputTypes[itemName] === "select" ? (
                        <>
                          <label>{data.placeholderText[itemName]}</label>
                          <div
                            className={styles[inputStyle].resetFieldButton}
                            onClick={() =>
                              resetValue(data.inputNames[itemName])
                            }
                            style={
                              values[data.inputNames[itemName]]
                                ? {
                                    display: "block",
                                  }
                                : { display: "none" }
                            }
                          >
                            <ICONS.CLOSE size={16} />
                          </div>
                          <Field
                            className={styles[inputStyle].fieldInput}
                            as={
                              data.inputTypes[itemName] === "select" &&
                              data.inputTypes[itemName]
                            }
                            name={data.inputNames[itemName]}
                          >
                            <option style={{ display: "none" }}>Select</option>
                            {data.inputOptions[itemName].map((item: string) => {
                              return (
                                <>
                                  <option key={`${index}+${item}`} value={item}>
                                    {item}
                                  </option>
                                </>
                              );
                            })}
                          </Field>
                        </>
                      ) : (
                        <>
                          <FormObserver />

                          {inputStyle === "square" && (
                            <label>{data.placeholderText[itemName]}</label>
                          )}
                          <Field
                            className={classNames(
                              styles[inputStyle].fieldInput
                            )}
                            type={
                              data.inputTypes[itemName] !== "textarea"
                                ? data.inputTypes[itemName]
                                : ""
                            }
                            component={
                              data.inputTypes[itemName] === "textarea"
                                ? data.inputTypes[itemName]
                                : ""
                            }
                            name={data.inputNames[itemName]}
                            placeholder={
                              (inputStyle === "round" &&
                                data.placeholderText[itemName]) ||
                              ""
                            }
                            onFocus={onFocus}
                          />
                        </>
                      )}
                      {/* Bit of a hacky workaround with touched, but it seems like not all fields are */}
                      {touched[itemName] && errors[itemName] ? (
                        <div
                          key={index}
                          className={classNames(styles[inputStyle].error, {
                            [styles[inputStyle].checkboxError]:
                              data.inputTypes[itemName] === "checkbox",
                          })}
                        >
                          {errors[itemName]}
                        </div>
                      ) : null}
                    </div>
                  </React.Fragment>
                );
              })}
              <div>
                {requiredFieldsLabel && (
                  <div className={styles[inputStyle].requiredLabel}>
                    * Required fields
                  </div>
                )}
                {discountCodeNote && (
                  <div className={styles[inputStyle].requiredLabel}>
                    promotional codes and vouchers can be added on the next step
                  </div>
                )}
                {children}
                {data.submitButton ? <data.submitButton /> : <></>}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SimpleInput;
