/**
 * All Database interactions for the order table
 */

import { supabase } from "../../lib/supabase";
import { Order, CartItem } from "../../commonTypes";
import * as Sentry from "@sentry/browser";
import { partial_order } from "@prisma/client";

/**
 * Create a new order for a user
 */
export const createOrder = async (order: Order): Promise<Order | null> => {
  try {
    const { data, error } = await supabase.from("order").insert([order]);
    if (error) {
      Sentry.captureException(error);
      return null;
    } else {
      return data[0];
    }
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};

/**
 * Update order for a user
 */
export const updateOrder = async (
  order_id: number | string,
  user_id: number | string,
  updatedData: Partial<partial_order>
): Promise<partial_order> => {
  try {
    const { data, error } = await supabase
      .from("partial_order")
      .update(updatedData)
      .match({ id: order_id, user_id });
    console.log("ERR", error);
    return data[0];
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};

/**
 * Get Full order for user (no reliational data)
 * @returns
 */
export const getFullOrder = async (
  order_id,
  user_id
): Promise<partial_order> => {
  try {
    const { data, error } = await supabase
      .from("partial_order")
      .select(`*`)
      .match({ id: order_id, user_id: user_id });

    if (error) {
      console.log("ERR", error);
    }
    return data[0];
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};

export const getOrderByCheckoutId = async (
  checkoutId: string
): Promise<partial_order | null> => {
  try {
    const { data, error } = await supabase
      .from("partial_order")
      .select(`*`)
      .eq("stripe_checkout_id", checkoutId);

    if (error) {
      Sentry.captureException(error);
      throw error;
    }

    return data[0];
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }

  return null;
};
