import * as Sentry from "@sentry/browser";

import { supabase } from "../../lib/supabase";

export interface userProductFavorite {
  product_id: number;
  user_id: number;
  id?: number;
}

export const addUserProductFavorite = async (
  productFavorite: userProductFavorite
) => {
  const { data, error } = await supabase
    .from("user_product_favorites")
    .insert(productFavorite);
  if (error) {
    Sentry.captureException(error);
    return { error };
  } else {
    return data;
  }
};

export const removeUserProductFavorite = async ({
  product_id,
  user_id,
}: userProductFavorite) => {
  const { data, error } = await supabase
    .from("user_product_favorites")
    .delete()
    .match({ product_id, user_id });
  if (error) {
    Sentry.captureException(error);
    return { error };
  } else {
    return data;
  }
};
