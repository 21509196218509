import React from "react";

const ORLogo = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      // height="27.425"
      viewBox="-10694.316 -14151.938 46.632 27.425"
    >
      <g data-name="CompositeLayer">
        <g data-name="Group 568">
          <path
            d="M-10680.607-14124.513c-7.562 0-13.71-6.15-13.71-13.712 0-7.563 6.148-13.712 13.71-13.712 7.563 0 13.715 6.15 13.715 13.712 0 7.562-6.152 13.712-13.715 13.712m0-22.147c-4.647 0-8.432 3.785-8.432 8.435s3.785 8.435 8.432 8.435c4.652 0 8.438-3.785 8.438-8.435s-3.786-8.435-8.438-8.435"
            fillRule="evenodd"
            data-name="Path 336"
          />
          <path
            d="M-10663.272-14151.273v26.096h5.681v-20.735h9.906v-5.361h-15.587Z"
            fillRule="evenodd"
          />
        </g>
      </g>
      <path
        d="M-10663.272-14151.273v26.096h5.681v-20.735h9.906v-5.361h-15.587Z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};

export default ORLogo;
