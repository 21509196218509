/**
 * All Database interactions for the product_variant_stock_updates table
 */

import * as Sentry from "@sentry/browser";
import { supabase } from "../../lib/supabase";

export const updateProductVariantStockUpdate = async (
  product_variant_id: number | string,
  orderId: number,
  new_stock: number,
  old_stock: number
) => {
  try {
    const { data, error } = await supabase
      .from("product_variant_stock_updates")
      .insert([
        {
          product_variant_id,
          order_id: orderId,
          new_stock,
          old_stock,
        },
      ]);

    if (error) {
      console.log("ERR", error);
    }
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};
