import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
  useCallback,
} from "react";

import { AppContextInterface } from "./types";

const AppContext = createContext<AppContextInterface | null>(null);

const hardcodedHidden = [
  "56/62 (0-3m)",
  "3m",
  "6m",
  "2m",
  "m",
  "4m",
  "9m",
  "12m",
  "1y",
  // "1-2y",
  "12-18m",
  "18m-24m",
  "18m",
  "24m",
  "18-24m",
  "1.5y",
  "2y",
  "36m",
  "62/68 (3-6m)",
  "68/74 (6-9m)",
  "74/80 (9-12m)",
  "80/86 (12-18m)",
  "86/92 (18-24m)",
  "small",
  "medium",
  "xs",
  "large",
  "2",
  "4",
  "s",
  "2-4y",
  "92/98 (2-3y)",
  "3y",
  "extra large",
  "48m",
  "2-4y",
  "3",
  "4y",
  "98/104 (3-4y)",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
];

const AppProvider: FC<AppContextInterface> = ({
  children,
  settings,
  popups,
}: AppContextInterface) => {
  const [postcodes, setPostcodes] = useState([]);
  const [deliveryTimes, setDeliveryTimes] = useState([]);
  const [popupImage, setPopupImage] = useState(null);
  const [blockedDates, setBlockedDates] = useState([]);
  const [popupData, setPopupData] =
    useState<AppContextInterface["popupData"]>(null);
  const [subscriptions, setSubscriptions] = useState<
    {
      option: string;
      optionPrice: string;
      optionLimit: string;
      stripeId: string;
    }[]
  >([]);
  const [hiddenFilters, setHiddenFilters] = useState([]);
  const [pauseNewMembers, setPauseNewMembers] = useState<boolean>(null);

  const getDeliveryTimes = useCallback(() => {
    if (settings?.data?.deliveryTimes) {
      const deliveryTimesArray = settings.data.deliveryTimes.includes(",")
        ? settings.data.deliveryTimes.split(", ")
        : settings.data.deliveryTimes.split();
      setDeliveryTimes(deliveryTimesArray);
    }
  }, [settings.data.deliveryTimes]);

  const getBlockedDates = useCallback(() => {
    if (settings?.data?.blockedDates) {
      const blockedDatesArray = settings.data.blockedDates.includes(",")
        ? settings.data.blockedDates.split(", ")
        : settings.data.blockedDates.split();

      const formattedDates = formatBlockedDates(blockedDatesArray);
      setBlockedDates(formattedDates);
    }
  }, [settings.data.blockedDates]);

  const getSubscriptions = useCallback(() => {
    const mappedSubs = settings.data.subscriptionOption.map((item) => {
      return {
        option: item.option,
        optionPrice: item.optionPrice,
        optionLimit: item.optionLimit,
        stripeId: item.stripeId,
      };
    });
    setSubscriptions(mappedSubs);
  }, [settings.data.subscriptionOption]);
  const getHiddenFilters = useCallback(() => {
    if (settings?.data?.hiddenFilters) {
      const hiddenFiltersArray = settings.data.hiddenFilters.includes(",")
        ? settings.data.hiddenFilters.split(", ")
        : settings.data.hiddenFilters.split();

      const lcHiddenFilters = hiddenFiltersArray.map((item: string) =>
        item.toLowerCase().replace(",", "")
      );

      setHiddenFilters([...lcHiddenFilters, ...hardcodedHidden]);
    } else {
      setHiddenFilters(hardcodedHidden);
    }
  }, [settings.data.hiddenFilters]);

  const getPopups = useCallback(() => {
    const data = popups.data.slices1;
    const popupArray = data.map((item) => {
      const { popupName, title, bodyText, buttonText, image } = item.primary;
      return {
        popupName,
        title,
        bodyText,
        buttonText,
        image,
      };
    });
    setPopupData(popupArray);
  }, [popups.data.slices1]);

  const getPauseNewMembersStatus = () => {
    if (settings.data.pauseNewMembers === true) {
      setPauseNewMembers(true);
    }
  };

  useEffect(() => {
    getDeliveryTimes();
    getBlockedDates();
    getSubscriptions();
    getPopups();
    getHiddenFilters();
    getPauseNewMembersStatus();
    if (settings?.data?.postcodeList) {
      //checks if postcodeList contains more than one postcode and sets it/them to be all lowercase
      const postcodeArray = settings.data.postcodeList.includes(",")
        ? settings.data.postcodeList
            .split(", ")
            .map((postcode) => postcode.toLowerCase().replace(",", "")) // Add in empty space to make sure N1 doesnt match n16
        : settings.data.postcodeList.toLowerCase();

      setPostcodes(postcodeArray);
    }

    if (settings?.data?.popupImage) {
      setPopupImage(settings.data.popupImage);
    }
  }, [
    settings,
    getDeliveryTimes,
    getBlockedDates,
    getSubscriptions,
    getHiddenFilters,
  ]);

  const formatBlockedDates = (datesArr: string[]) => {
    return datesArr.map((date) => {
      const dateToBlock = new Date(date.split("/").reverse().join("-"));
      return {
        from: dateToBlock,
        to: dateToBlock,
      };
    });
  };

  return (
    <AppContext.Provider
      value={{
        postcodes,
        popupImage,
        deliveryTimes,
        settings,
        blockedDates,
        subscriptions,
        popupData,
        hiddenFilters,
        pauseNewMembers,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContextProvider.");
  }
  return context;
};

export { AppContext, AppProvider };
