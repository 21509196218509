import React from "react";

const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68.401"
      height="49.642"
      viewBox="0 0 68.401 49.642"
    >
      <path
        id="Path_139"
        data-name="Path 139"
        d="M43.583,0l-5.03,5.191L54.62,21.253H0v7.218H54.62L38.552,44.532l5.029,5.11L68.4,24.821Z"
        transform="translate(0)"
      />
    </svg>
  );
};

export default ArrowIcon;
