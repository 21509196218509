import React, { useEffect, useState } from "react";

import SEO from "../SEO";
import Header from "../header/Header";
import NewsletterSlice from "../slices/newsletter/NewsletterSlice";
import LogoComponent from "../logoComponent/LogoComponent";
import Footer from "../footer/Footer";
import Modal from "../modal/Modal";

//! Prelaunch
import { useRouter } from "next/router";
import CustomNewsletter from "../popups/CustomNewsletter";

import styles from "./layout.module.css";
import classNames from "classnames";
import { isIOS } from "../../utils/helpers";
const Layout = ({ children, pageData, settings }) => {
  //! Prelaunch
  const router = useRouter();
  const [displayFull, setDisplayFull] = useState(
    typeof window !== "undefined" && !!localStorage.getItem("displayFull")
  );

  useEffect(() => {
    if (isIOS) {
      document.querySelector("body").classList.add("ios-device");
    }
  }, [isIOS]);

  useEffect(() => {
    if (router.query.enableTest) {
      setDisplayFull(true);
      localStorage.setItem("displayFull", "true");
    }
  }, [router]);
  const isHomepage = /*router.asPath === "/";*/ true;

  //! end prelaunch

  const pageType = pageData?.page;
  return (
    <div className={classNames(styles.layout)}>
      <SEO settings={settings} pageData={pageData} />
      <Header settings={settings}></Header>
      <Modal />
      <main>
        {/* {displayFull ? ( */}
        <>
          {isHomepage && <div className={styles.content}>{children}</div>}
          {router.query.subscribe2023 && <CustomNewsletter />}
        </>
        {/* ) : ( */}
        {/* <div className={styles.cover}>
            <LogoComponent />
            <p>
              Our website will be down until Friday 08th December for some
              maintenance work. For any urgent queries, please get in touch via{" "}
              <a href="mailto:hello@orcollective.co.uk">
                hello@orcollective.co.uk
              </a>
            </p>
          </div>
        )} */}
      </main>
      <NewsletterSlice pageType={pageType} settings={settings} socials />
      <LogoComponent />
      <Footer settings={settings}></Footer>
    </div>
  );
};

export default Layout;
