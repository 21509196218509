import React, { useContext, FC, useRef } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import { ModalContext } from "../../context/ModalContext";
import ICONS from "../constants/icons";
import { ModalProps } from "./types";
import { useOutsideClickHandler } from "../../utils/helpers";

import styles from "./modal.module.css";

//!prelaunch
import { useRouter } from "next/router";

export const Modal: FC<ModalProps> = ({ onClose, children, ...props }) => {
  const { modal, handleModal, modalContent, withImage, basket, fullScreen } =
    useContext(ModalContext);
  const modalRef = useRef(null);
  const router = useRouter();
  useOutsideClickHandler(modalRef, () =>
    handleModal({ content: false, withImage: false })
  );

  return modal && modalContent
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className={styles.modalContainer}>
            <div className={styles.modalOverlay} />
            <div
              className={classNames(styles.modalWrapper, {
                [styles.fullScreenWrapper]: fullScreen,
              })}
              aria-modal
              aria-hidden
              tabIndex={-1}
              role="dialog"
            >
              <div
                className={classNames({
                  [styles.modal]: !withImage && !basket,
                  [styles.modalWide]: withImage,
                  [styles.modalBasket]: basket,
                  [styles.fullScreen]: fullScreen,
                })}
                ref={modalRef}
              >
                <button
                  type="button"
                  className={classNames(styles.modalCloseButton, {
                    [styles.modalCloseButtonBasket]: basket,
                  })}
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleModal({ content: false, withImage: false });
                    fullScreen && router.push("/");
                  }}
                >
                  {/* <span aria-hidden="true">&times;</span> */}
                  {basket && <div className={styles.closeMessage}>Close</div>}
                  <ICONS.CLOSE size={basket ? 38 : 22} />
                </button>
                <div className={styles.modalHeader}>
                  <div className={styles.modalContent}>{modalContent}</div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default Modal;
