import * as Yup from "yup";

// TODO - update postcode schema + error message
const postcodeSchema = Yup.object().shape({
  postcode: Yup.string()
    .matches(
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g,
      {
        excludeEmptyString: true,
        message: "Please enter a valid postcode",
      }
    )
    .required("Please enter a postcode"),
  // postcode: Yup.string().required("required field"),
});

// TODO -
const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter an email address"),
});

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("required field"),
  password: Yup.string().required("required field"),
});

// TODO - ask for any specific password reqs from client
const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("required field"),
  password: Yup.string()
    .min(8, "Minimum 8 characters")
    .max(50, "Maximum 50 characters")
    .required("required field"),
  password2: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("required field"),
});

const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("required field"),
});

// TODO - update validaiton for profile
const accountDetailsSchema = Yup.object().shape({
  title: Yup.string(),
  firstName: Yup.string().required("required field"),
  lastName: Yup.string().required("required field"),
  babyName: Yup.string().required("required field"),
  email: Yup.string().required("required field"),
  contactNumber: Yup.string()
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, {
      excludeEmptyString: true,
      message: "Please enter a valid contact number, (no spaces)",
    })
    .required("required field"),
  address1: Yup.string().required("required field"),
  address2: Yup.string(),
  city: Yup.string().required("required field"),
  postcode: Yup.string()
    .matches(
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g,
      {
        excludeEmptyString: true,
        message: "Please enter a valid postcode",
      }
    )
    .required("required field"),
  country: Yup.string().required("required field"),
});

// TODO - update validaiton for contact
const contactSchema = Yup.object().shape({
  surname: Yup.string(),
  firstName: Yup.string().required("required field"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("required field"),
  subject: Yup.string().required("required field"),
  message: Yup.string().required("required field"),
});

const deliverySchema = Yup.object().shape({
  title: Yup.string(),
  firstName: Yup.string().required("required field"),
  address1: Yup.string().required("required field"),
  address2: Yup.string(),
  city: Yup.string().required("required field"),
  postcode: Yup.string()
    .matches(
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g,
      {
        excludeEmptyString: true,
        message: "Please enter a valid postcode",
      }
    )
    .required("required field"),
  country: Yup.string().required("required field"),
  termsAccepted: Yup.bool()
    .oneOf([true], "You must read and agree to the terms and conditions")
    .required("You must read and agree to the terms and conditions"),
  contactNumber: Yup.string()
    .matches(
      /^(?:(?:\+44)?(?:\s|-|\.)?(?:\d(?:\s|-|\.)?)?(?:(?:1\d{3})|(?:7[1-9]\d{2})|(?:20(?:[78]\d|9[0-3]))|(?:3[0-9]{2})|(?:1[1-9]\d)|(?:2\d{2})))\s?(?:\d(?:\s|-|\.)?)?(?:\d(?:\s|-|\.)?)?(?:\d(?:\s|-|\.)?)?\d{4}$/,
      {
        excludeEmptyString: true,
        message: "Please enter a valid phone number",
      }
    )
    .required("required field"),
});
const deliverySchemaNoTerms = Yup.object().shape({
  title: Yup.string(),
  firstName: Yup.string().required("required field"),
  address1: Yup.string().required("required field"),
  address2: Yup.string(),
  city: Yup.string().required("required field"),
  postcode: Yup.string()
    .matches(
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g,
      {
        excludeEmptyString: true,
        message: "Please enter a valid postcode",
      }
    )
    .required("required field"),
  country: Yup.string().required("required field"),
  contactNumber: Yup.string()
    .matches(
      /^(?:(?:\+44)?(?:\s|-|\.)?(?:\d(?:\s|-|\.)?)?(?:(?:1\d{3})|(?:7[1-9]\d{2})|(?:20(?:[78]\d|9[0-3]))|(?:3[0-9]{2})|(?:1[1-9]\d)|(?:2\d{2})))\s?(?:\d(?:\s|-|\.)?)?(?:\d(?:\s|-|\.)?)?(?:\d(?:\s|-|\.)?)?\d{4}$/,
      {
        excludeEmptyString: true,
        message: "Please enter a valid phone number",
      }
    )
    .required("required field"),
});

const deliverySignUpSchema = Yup.object().shape({
  title: Yup.string(),
  firstName: Yup.string().required("required field"),
  lastName: Yup.string().required("required field"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("required field"),
  contactNumber: Yup.string()
    .matches(
      /^(?:(?:\+44)?(?:\s|-|\.)?(?:\d(?:\s|-|\.)?)?(?:(?:1\d{3})|(?:7[1-9]\d{2})|(?:20(?:[78]\d|9[0-3]))|(?:3[0-9]{2})|(?:1[1-9]\d)|(?:2\d{2})))\s?(?:\d(?:\s|-|\.)?)?(?:\d(?:\s|-|\.)?)?(?:\d(?:\s|-|\.)?)?\d{4}$/,
      {
        excludeEmptyString: true,
        message: "Please enter a valid phone number",
      }
    )
    .required("required field"),
  password: Yup.string()
    .min(8, "Minimum 8 characters")
    .max(50, "Maximum 50 characters")
    .required("required field"),
  password2: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("required field"),
  // address1: Yup.string().required("required field"),
  // address2: Yup.string(),
  // city: Yup.string().required("required field"),
  // postcode: Yup.string().required("required field"),
  // country: Yup.string().required("required field"),
  termsAccepted: Yup.bool()
    .oneOf([true], "You must read and agree to the terms and conditions")
    .required("You must read and agree to the terms and conditions"),
});

const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Minimum 8 characters")
    .max(50, "Maximum 50 characters")
    .required("required field"),
  password2: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("required field"),
});

const giftAmountSchema = Yup.object().shape({
  giftAmount: Yup.string().matches(/^[0-9]*$/, {
    excludeEmptyString: true,
    message: "Value must be a number",
  }),
  // .test(
  //   "type",
  //   "Value must be a number",
  //   (value) => (value && !isNaN(Number(value))) || value === ""
  // )
  // .test("validDollar", "Value must be a valid amount", (value) =>
  //   /^\d*.?\d{0,2}$/.test(value)
  // ),
});

const giftUserSchema = Yup.object().shape({
  title: Yup.string(),
  firstName: Yup.string().required("required field"),
  lastName: Yup.string().required("required field"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("required field"),
  phoneNumber: Yup.string(),
});

const giftRecipientSchema = Yup.object().shape({
  title: Yup.string(),
  firstName: Yup.string().required("required field"),
  lastName: Yup.string().required("required field"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("required field"),
  phoneNumber: Yup.string(),
});

const giftMessageSchema = Yup.object().shape({
  message: Yup.string().max(500, "Maximum 500 characters"), // TODO - let bliss set this
});

// Exports
const VALIDATION = {
  POSTCODE: postcodeSchema,
  EMAIL: emailSchema,
  LOGIN: loginSchema,
  SIGNUP: signUpSchema,
  ACCOUNT: accountDetailsSchema,
  CONTACT: contactSchema,
  DELIVERY: deliverySchema,
  DELIVERY_NO_TERMS: deliverySchemaNoTerms,
  DELIVERYSIGNUP: deliverySignUpSchema,
  RESETPASSWORD: resetPasswordSchema,
  NEWPASSWORD: newPasswordSchema,
  GIFTAMOUNT: giftAmountSchema,
  GIFTUSER: giftUserSchema,
  GIFTRECIPIENT: giftRecipientSchema,
  GIFTMESSAGE: giftMessageSchema,
};

export default VALIDATION;
