import React, { useContext, useState } from "react";

import { ModalContext } from "../../context/ModalContext";
import LoginForm from "./LoginForm";
import SimpleInput from "../input/SimpleInput";
import Button from "../button/Button";
import FORMS from "../constants/forms";
import { LoginValues } from "./types";

import styles from "./authForm.module.css";
import { resetPassword } from "../../modules/@supabase/auth";

const ResetPasswordForm = () => {
  const [error, setError] = useState(null);
  const [buttonText, setButtonText] = useState("Continue");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { handleModal } = useContext(ModalContext);

  const handleResetPassword = async ({ email }: LoginValues) => {
    await resetPassword({ email });
    setButtonText("Password reset email sent");
    setButtonDisabled(true);
  };

  const handleCancelClick = () => {
    handleModal({
      content: <LoginForm />,
      withImage: false,
    });
  };

  const SubmitButton = (): JSX.Element => {
    return (
      <div className={styles.button}>
        <Button type="submit" text={buttonText} disabled={buttonDisabled} />
      </div>
    );
  };

  const formData = {
    ...FORMS.RESETPASSWORD,
    handleSubmit: handleResetPassword,
    submitButton: SubmitButton,
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modalTitle}>Reset your password</div>
        <div className={styles.modalBody}>
          Enter your email below and details of how to reset your password will
          be sent to you.
        </div>
        <div className={styles.buttonContainer}>
          <SimpleInput data={formData} />
          {error && <div className={styles.error}>{error}</div>}
          <div className={styles.button}>
            <Button text="Back" onClick={handleCancelClick}></Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
