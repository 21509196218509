import React, { useEffect } from "react";
import NextApp, { AppProps } from "next/app";

import Layout from "../components/layout/Layout";
import { createClient } from "../prismic";
import { UserContextProvider, useUser } from "../context/UserContext";
import { AppProvider } from "../context/AppContext";
import { ModalProvider } from "../context/ModalContext";
import { ShopProvider } from "../context/ShopContext";

//sylesheets
import "../styles/globals.css";
import "../styles/variables.css";
import "../styles/fonts.css";
import "pure-react-carousel/dist/react-carousel.es.css";

interface CustomAppProps extends AppProps {
  settings: any; // TODO: make this a typ
  popups: any;
}

function MyApp({ Component, pageProps, ...props }: CustomAppProps) {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };

  useEffect(() => {
    window.addEventListener("resize", appHeight);
    appHeight();

    return () => {
      window.removeEventListener("resize", appHeight);
    };
  }, []);
  return (
    <AppProvider settings={props.settings} popups={props.popups}>
      <ModalProvider>
        <UserContextProvider>
          <ShopProvider>
            <Layout settings={props.settings} pageData={pageProps}>
              <Component {...pageProps} pageData={pageProps} />
            </Layout>
          </ShopProvider>
        </UserContextProvider>
      </ModalProvider>
    </AppProvider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const client = createClient();

  const settings = (await client.getSingle("settings")) || {};
  const popups = (await client.getSingle("popups")) || {};

  return {
    settings: settings,
    popups: popups,
  };
};

export default MyApp;
