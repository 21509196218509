import * as prismic from "@prismicio/client";
import { enableAutoPreviews } from "@prismicio/next";
import sm from "./sm.json";

export const endpoint = sm.apiEndpoint;
export const repositoryName = prismic.getRepositoryName(endpoint);

// Update the Link Resolver to match your project's route structure
export const linkResolver = (doc) => {
  switch (doc.type) {
    case "home":
      return "/";
    case "page":
      return `/${doc.uid}`;
    default:
      return null;
  }
};

// This factory function allows smooth preview setup
export const createClient = (config = {}) => {
  const client = prismic.createClient(endpoint, {
    ...config,
  });

  // enableAutoPreviews({
  //   client,
  //   previewData: config.previewData,
  //   req: config.req,
  // });

  return client;
};
