export default function customImageLoader({ src, width, quality }) {
  if (src.indexOf("supabase") > -1) {
    return (
      src.replace(
        "https://enmxpvwsulzprqmaxwsb.supabase.co/storage/v1/object/public/images/public",
        "https://enmxpvwsulzprqmaxwsb.supabase.co/storage/v1/render/image/public/images/public"
      ) + `?width=${width}&resize=contain`
    );
  } else {
    return src;
  }
  // return `https://example.com/${src}?w=${width}&q=${quality || 75}`;
}
