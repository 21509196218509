import React, { useState } from "react";
import { useRouter } from "next/router";
import { RichText } from "prismic-reactjs";

import ICONS from "../constants/icons";
import FORMS from "../constants/forms";
import SimpleInput from "../input/SimpleInput";
import Button from "../button/Button";
import { addSubscriber } from "../../modules/@klaviyo";
import { KlaviyoListCodes } from "../../commonTypes";

import styles from "./standardPopup.module.css";

interface Props {
  popupContent: {
    title: any;
    bodyText: any;
    buttonText: string;
    image: any;
  };
  logoTop?: boolean;
  waitlist?: boolean;
  newsletterList?: boolean;
}

const StandardPopup: React.FC<Props> = ({
  popupContent: { title, bodyText, buttonText, image },
  logoTop,
  waitlist,
  newsletterList,
}): JSX.Element => {
  const [success, setSuccess] = useState(null);

  const router = useRouter();

  const declinedButton = () => {
    return (
      <div className={styles.buttonDeclined}>
        <Button
          type="submit"
          disabled={success}
          text={success ? "Email subscribed!" : buttonText}
        />
      </div>
    );
  };

  const handleSubmit = (values) => {
    addSubscriber(values.email, {
      ...values,
      list: newsletterList
        ? KlaviyoListCodes.NEWSLETTER
        : KlaviyoListCodes.WAITLIST, //customer newsletter list : waitlist
    }).then(() => setSuccess(true));
  };

  const formData = {
    ...FORMS.NEWSLETTER,
    handleSubmit: handleSubmit,
    submitButton: declinedButton,
  };

  const formDataWaitlist = {
    ...FORMS.POSTCODE_NEWSLETTER,
    handleSubmit: handleSubmit,
    submitButton: declinedButton,
  };

  return (
    <div className={styles.container}>
      <div className={styles.modalLeft}>
        {logoTop && <ICONS.ORLOGO size={100} />}
        <div className={styles.description}>
          <div className={styles.modalTitle}>
            <RichText render={title} />
          </div>
          <div className={styles.modalBody}>
            <RichText render={bodyText} />
          </div>
        </div>
        {/* Newsletter form section */}
        <div className={styles.newsletterForm}>
          <SimpleInput data={waitlist ? formDataWaitlist : formData} />
        </div>

        {!logoTop && <ICONS.ORLOGO size={100} />}
      </div>
      <div
        className={styles.modalRight}
        style={{ backgroundImage: `url(${image?.url})` }}
      ></div>
    </div>
  );
};

export default StandardPopup;
