import React from "react";

const TiktokIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15.805 18.009"
    >
      <path
        id="Path_134"
        data-name="Path 134"
        d="M1229.741,96.471v2.96a1.192,1.192,0,0,1-.13-.018,2.658,2.658,0,1,0,1.913,3.081,2.713,2.713,0,0,0,.051-.541q0-6.106,0-12.213v-.192h2.939a4.576,4.576,0,0,0,4.548,4.5v2.926a7.484,7.484,0,0,1-4.535-1.537v.2q.005,3.143.01,6.285a5.633,5.633,0,1,1-6.834-5.395,5.68,5.68,0,0,1,1.963-.071c.021,0,.042.01.071.017"
        transform="translate(-1223.261 -89.548)"
        fill="#fff"
      />
    </svg>
  );
};

export default TiktokIcon;
