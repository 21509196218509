import React from "react";

const FacebookIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 11.109 19.487"
    >
      <path
        id="Path_133"
        data-name="Path 133"
        d="M128.37,93.37v8.157h-3.751V93.37h-3.112V90.064h3.112V88.856c0-4.469,1.864-6.817,5.814-6.817a6.488,6.488,0,0,1,2.179.353v3.272a7.476,7.476,0,0,0-1.725-.2,2.151,2.151,0,0,0-1.849.768,4.09,4.09,0,0,0-.668,2.663v1.168h4.246l-1.141,3.306Z"
        transform="translate(-121.507 -82.039)"
        fill="#fff"
      />
    </svg>
  );
};

export default FacebookIcon;
