import React from "react";

const BackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.19"
      height="15.115"
      viewBox="0 0 18.19 15.115"
    >
      <g
        id="Group_856"
        data-name="Group 856"
        transform="translate(-32.293 -85.236)"
      >
        <path
          id="Path_495"
          data-name="Path 495"
          d="M0,0,7.2,7.2l7.2-7.2"
          transform="translate(40.204 85.59) rotate(90)"
          fill="none"
          stroke="#000"
          strokeWidth="1"
        />
        <path
          id="Path_498"
          data-name="Path 498"
          d="M-15746-21492.207h17.483"
          transform="translate(15779 21585)"
          fill="none"
          stroke="#000"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default BackIcon;
