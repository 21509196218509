import React from "react";

const HeartIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 19.493 17.508"
    >
      <defs>
        <style>
          {`
      .cls-1 {
        fill: none;
        stroke: #777;
        stroke-miterlimit: 10;
      }`}
        </style>
      </defs>
      <path
        id="Path_71"
        data-name="Path 71"
        className="cls-1"
        d="M17.531,1.961a4.992,4.992,0,0,0-7.785.927A4.989,4.989,0,1,0,1.96,9.016L9.746,16.8l7.785-7.785A4.988,4.988,0,0,0,17.531,1.961Z"
        transform="translate(0.001 0)"
      />
    </svg>
  );
};

export default HeartIcon;
