import React from "react";
import Link from "next/link";

import { useDesktopMediaQuery } from "../../utils/helpers";
import InternalLink from "../InternalLink";

import styles from "./footer.module.css";
import ICONS from "../constants/icons";

const Footer = ({ settings }) => {
  const footerNavItems = settings?.data?.slices2;
  const copyrightText = settings?.data?.footerCopyrightText;
  const contactText = settings?.data?.footerRightSideText;
  const isDesktop = useDesktopMediaQuery();

  const MobileFooter = () => {
    return (
      <div className={styles.footerContainer}>
        <div className={styles.footer}>
          <div className={styles.footerLeft}>
            <div className={styles.linkItem}>
              <a>{copyrightText}</a>
            </div>
            <div className={styles.footerLeftLink}>
              {footerNavItems.map((item, index) => {
                let path =
                  item.primary.path?.uid?.slice() || item.primary.path?.url;

                if (path === "home") {
                  path = "/";
                }

                if (item.primary.itemName === "About") {
                  path = "/about";
                }

                if (item.primary.itemName === "Contact") {
                  path = "/contact";
                }

                let extraProps = {};

                if (item.primary?.path?.url?.indexOf("cookie-policy") > -1) {
                  //@ts-ignore
                  extraProps.onClick = (ev) => {
                    ev.preventDefault();
                    //@ts-ignore
                    document.querySelector(".cky-btn-revisit-wrapper")?.click();
                  };
                }
                return (
                  <div key={index} className={styles.linkItem}>
                    <InternalLink href={path || ""}>
                      <a className={styles.linkTitle} {...extraProps}>
                        {item.primary.itemName}
                      </a>
                    </InternalLink>
                  </div>
                );
              })}

              <div style={{ marginTop: "16px" }}>
                Web design by{" "}
                <a href="https://studioparallel.co.uk/">studio parallel</a>
                <br />
                Build by <a href="https://thisisundefined.com">undefined</a>
              </div>
            </div>
          </div>
          <div className={styles.footerRight}>
            <div className={styles.socialIcon}>
              <a
                href={"https://www.instagram.com/or_collective/"}
                target="_blank"
                rel="noreferrer"
              >
                <ICONS.INSTAGRAM size={17} />
              </a>
            </div>
            <div className={styles.socialIcon}>
              <a
                href={"https://www.tiktok.com/@orcollective"}
                target="_blank"
                rel="noreferrer"
              >
                <ICONS.TIKTOK size={17} />
              </a>
            </div>
            <div className={styles.socialIcon}>
              <a
                href={"https://www.facebook.com/ORCollectivelimited"}
                target="_blank"
                rel="noreferrer"
              >
                <ICONS.FACEBOOK size={17} />
              </a>
            </div>
            {/* <div className={styles.linkItem}>
              <Link href={""}>
                <a className={styles.linkTitle}>Instagram</a>
              </Link>
            </div>
            {footerNavItems.map((item, index) => {
              if (
                item.primary.itemName === "Contact" ||
                item.primary.itemName === "Home"
              ) {
                return;
              }
              let path = item.primary.path?.slug?.slice();

              return (
                <div key={index} className={styles.linkItem}>
                  <Link href={path || ""}>
                    <a className={styles.linkTitle}>{item.primary.itemName}</a>
                  </Link>
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
    );
  };

  const DesktopFooter = () => {
    return (
      <div className={styles.footerContainer}>
        <div className={styles.footer}>
          <div className={styles.footerLeft}>
            <div className={styles.linkItem}>
              <a>{copyrightText}</a>
            </div>
            <div className={styles.footerLeftLink}>
              {footerNavItems.map((item, index) => {
                let path = item.primary.path?.uid?.slice();

                if (path === "home") {
                  path = "/";
                }

                if (item.primary.itemName === "About") {
                  path = "/about";
                }

                if (item.primary.itemName === "Contact") {
                  path = "/contact";
                }

                let extraProps = {};

                if (item.primary?.path?.url?.indexOf("cookie-policy") > -1) {
                  //@ts-ignore
                  extraProps.onClick = (ev) => {
                    ev.preventDefault();
                    //@ts-ignore
                    document.querySelector(".cky-btn-revisit-wrapper")?.click();
                  };
                }

                if (item.primary.path.url) {
                  path = item.primary.path.url;
                }

                return (
                  <div key={index} className={styles.linkItem}>
                    <InternalLink key={`${path}-${index}`} href={path || ""}>
                      <a className={styles.linkTitle} {...extraProps}>
                        {item.primary.itemName}
                      </a>
                    </InternalLink>
                  </div>
                );
              })}
              <div style={{ marginTop: "16px" }}>
                Web design by{" "}
                <a href="https://studioparallel.co.uk/">studio parallel</a>
                <br />
                Build by <a href="https://thisisundefined.com">undefined</a>
              </div>
            </div>
          </div>

          <div className={styles.footerRight}>
            <div className={styles.socialIcon}>
              <a
                href={"https://www.instagram.com/or_collective/"}
                target="_blank"
                rel="noreferrer"
              >
                <ICONS.INSTAGRAM size={17} />
              </a>
            </div>
            <div className={styles.socialIcon}>
              <a
                href={"https://www.tiktok.com/@orcollective"}
                target="_blank"
                rel="noreferrer"
              >
                <ICONS.TIKTOK size={17} />
              </a>
            </div>
            <div className={styles.socialIcon}>
              <a
                href={"https://www.facebook.com/ORCollectivelimited"}
                target="_blank"
                rel="noreferrer"
              >
                <ICONS.FACEBOOK size={17} />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return isDesktop ? <DesktopFooter /> : <MobileFooter />;
};

export default Footer;
