import { Order, OrderStatusTypes, ProductVariant } from "../commonTypes";

export const getLastActiveOrderFromList = (
  orders: Order[],
  order_id?: number
): Order => {
  /**
   * Sorts orders so most recent order ID (highest number) is at index[0] of the array
   */
  const sortedOrders = orders
    .filter(
      (ord) =>
        ord.status === OrderStatusTypes.ACTIVE ||
        ord.status === OrderStatusTypes.PLACED
    )
    .sort((a, b) => {
      return b.id - a.id;
    });

  /**
   * Returns the most recent order if no order_id is given to compare it to
   */
  if (!order_id) {
    if (!sortedOrders || !sortedOrders[0]) {
      return null;
    }
    return sortedOrders[0];
  }

  /**
   * Finds previous order -- not the current order_id and numerically less than the current order_id (older)
   */
  const prevOrder = sortedOrders.find(
    (ord) => ord.id !== order_id && ord.id < order_id
  );

  return prevOrder;
};

/**
 * Find the overlapping variants between 2 orders to know which one need to be updated
 */

type FindOverlappingOrderVariantsReturn = {
  /**
   * The variants that are in both order 1 and 2
   */
  overlappingVariants: number[];
  /**
   * The variants that are in the mainOrder but not in compare Order
   */
  uniqueVariants: number[];
  /**
   * The variants that are in the compare order but not in main order
   */
  oldVariants: number[];
};

/**
 * Find the overlapping product variants between 2 ordeers
 */
export const findOverlappingOrderVariants = (
  mainOrder: Order,
  compareOrder?: Order
): FindOverlappingOrderVariantsReturn => {
  /**
   * Assigns the arrays of { order_product_variant: id }'s for each order to a variable
   */
  const mainVariants = (mainOrder?.order_product_variants || []).map(
    (variant) => variant.product_variant_id
  );
  const compareVariants = (compareOrder?.order_product_variants || []).map(
    (variant) => variant.product_variant_id
  );

  /**
   * Creates empty return arrays
   */
  const overlappingVariants: number[] = [];
  const uniqueVariants: number[] = [];

  mainVariants.forEach(function (prodVarId) {
    if (compareVariants.indexOf(prodVarId) !== -1) {
      // If variant exist in the compare variants, then it's overlapping
      overlappingVariants.push(prodVarId);
      // This one has been overlapped, so let's remove it in case there are multiple
      compareVariants.splice(compareVariants.indexOf(prodVarId), 1);
    } else {
      // Otherwise, we know it's a unique one
      uniqueVariants.push(prodVarId);
    }
  });

  var oldVariants = compareVariants.filter(
    (varId) => overlappingVariants.indexOf(varId) < 0
  );

  return {
    overlappingVariants,
    uniqueVariants,
    oldVariants,
  };
};

/**
 * Get the delivery type based on the postcode
 */
export const getDeliveryTypeForPostcode = (
  currentPostcode: string,
  postcodes: string[]
): null | "48h" | "4d" => {
  let deliveryType;

  if (!currentPostcode || currentPostcode?.replaceAll(" ", "").length < 5) {
    deliveryType = null;
  } else {
    // run postcode through checker
    const currentPostcodeLc = currentPostcode.toLowerCase();
    const postcodeNoWhitespace = currentPostcodeLc.replaceAll(" ", "");
    const splitPostcode = postcodeNoWhitespace.slice(
      0,
      postcodeNoWhitespace.length - 3
    );
    const shortDelivery = !!postcodes.includes(splitPostcode);

    if (shortDelivery) {
      deliveryType = "48h";
    } else {
      deliveryType = "4d";
    }
  }

  return deliveryType;
};
