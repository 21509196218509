/**
 * All Database interactions for the product_variants table
 */

import * as Sentry from "@sentry/browser";
import { supabase } from "../../lib/supabase";

/**
 * Update one of the product variants
 * @param updatedData
 * @returns
 */
export const updateProductVariant = async (product_variant_id, updatedData) => {
  try {
    const { data, error } = await supabase
      .from("product_variants")
      .update(updatedData)
      .match({ id: product_variant_id });

    if (error) {
      console.log("ERR", error);
    }
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};
