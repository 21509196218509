import React, { useEffect, useState, createContext, useContext } from "react";
import { Session, User } from "@supabase/supabase-js";

import { supabase } from "../lib/supabase";
import { UserProfile } from "./types";
import { OrderStatusTypes } from "../commonTypes";
import { ModalContext } from "../context/ModalContext";
import LoginForm from "../components/auth/LoginForm";
import {
  addUserProductFavorite,
  removeUserProductFavorite,
} from "../modules/@supabase/userProductFavorite";

import { getUserById } from "../modules/@supabase/user";
import {
  CartItemProduct,
  setOrderToCartItems,
} from "../utils/productFunctions";
import { OrderService } from "../services/order.service";
import { getPartialOrderProducts } from "../modules/@supabase/orderProductVariant";
import { $Enums } from "@prisma/client";

export const UserContext = createContext<{
  user: User | null;
  userProfile: UserProfile | null;
  refreshUserProfile: () => Promise<void>;
  session: Session | null;
  authorize: () => void;
  toggleProductFavorite: (product_id: number) => void;
  signOut: () => void;
  userFavorites: number[];
  getUserProfile: (id: number | string) => void;
  cancelUserOrder: (order_id: number | string) => Promise<any | null>;
  getUserActiveOrderProducts: () => void;
  activeOrderProducts: [];
  previousOrderProducts: [];
  upcomingOrderProducts: [];
  activeOrderSubscriptionPlan: string | null;
  userSubscribed: boolean;
}>({
  user: null,
  userProfile: null,
  refreshUserProfile: async () => {},
  session: null,
  toggleProductFavorite: (product_id) => {},
  signOut: () => {},
  authorize: () => {},
  userFavorites: [],
  getUserActiveOrderProducts: () => {},
  getUserProfile: (user_id) => {},
  cancelUserOrder: async (order_id) => {},
  activeOrderProducts: [],
  previousOrderProducts: [],
  upcomingOrderProducts: [],
  activeOrderSubscriptionPlan: null,
  userSubscribed: null,
});

// TODO - update types and move to ./types
export const UserContextProvider = (props: any) => {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const { handleModal } = useContext(ModalContext);

  const [activeOrderProducts, setActiveOrderProducts] = useState<
    CartItemProduct[]
  >([]);
  const [upcomingOrderProducts, setUpcomingOrderProducts] = useState<
    CartItemProduct[]
  >([]);
  const [previousOrderProducts, setPreviousOrderProducts] = useState<
    CartItemProduct[]
  >([]);
  const [userSubscribed, setUserSubscribed] = useState<boolean>(null);

  const [activeOrderSubscriptionPlan, setActiveOrderSubscriptionPlan] =
    useState<string | null>(null);
  const userFavorites =
    userProfile?.user_product_favorites?.map((fav) => fav.product_id) || [];

  useEffect(() => {
    authorize();
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (userProfile && typeof window !== "undefined" && window?._learnq) {
      //@ts-ignore
      window._learnq.push([
        "identify",
        {
          $email: userProfile.email,
          $first_name: userProfile.firstName,
          $last_name: userProfile.lastName,
        },
      ]);

      getUserActiveOrderProducts();
    }
    //@ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, typeof window !== "undefined" && window?._learnq]);

  //listens for auth user, updates userProfile with user information
  useEffect(() => {
    if (user) {
      getUserProfile(user?.identities[0]?.id);
    }
  }, [user]);

  const refreshUserProfile = async () => {
    if (user) {
      await getUserProfile(user?.identities[0]?.id);
    }
  };

  //gets and sets userProfile from auth user
  const getUserProfile = async (id: number | string) => {
    const usr = await getUserById(process.env.NEXT_PUBLIC_EMULATED_USER || id);

    if (usr.postcode) {
      localStorage.setItem("postcode", usr.postcode);
    }

    if (
      usr.stripe_subscription_id &&
      usr.activeOrder?.status !== OrderStatusTypes.FINALISED
    ) {
      setUserSubscribed(true);
    }

    setUserProfile(usr);
  };

  //authorizes user and sets user
  const authorize: () => void = () => {
    const currentSession = supabase.auth.session();
    setSession(currentSession);
    setUser(currentSession?.user ?? null);
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, sesh) => {
        if (sesh) {
          setSession(sesh);
          //TODO - maybe set the user as the user from getUserProfile instead of the user straight from auth
          setUser(sesh?.user ?? null);
        }
      }
    );
    return () => {
      authListener!.unsubscribe();
    };
  };

  const cancelUserOrder = async (order_id) => {
    const order = await OrderService.getOrder({ order_id, user_id: user.id });

    if (order) {
      const result = await OrderService.updateOrder({
        order_id,
        user_id: userProfile.id,
        updatedData: {
          status: OrderStatusTypes.PLACED_PAYMENT_CANCELLED,
        },
      });
    }
  };

  const signOut: () => void = async () => {
    const { error } = await supabase.auth.signOut();
    setUserProfile(null);
    setSession(null);
    setUser(null);
    setActiveOrderProducts(null);
    setUpcomingOrderProducts(null);
    setPreviousOrderProducts(null);
  };

  /* OR Collective Specific Actions */

  const toggleProductFavorite = async (product_id: number) => {
    if (!userProfile) {
      handleModal({ content: <LoginForm />, withImage: false });
      return;
    }

    const userProductFavorite = {
      product_id,
      user_id: userProfile.id,
    };

    if (userFavorites.indexOf(product_id) > -1) {
      // Remove favorite
      //@ts-ignore
      await removeUserProductFavorite(userProductFavorite);
    } else {
      // Add favorite
      //@ts-ignore
      await addUserProductFavorite(userProductFavorite);
    }

    await getUserProfile(userProfile.id);
  };

  //Takes user active order and gets product_variant_ids from the database relating to that order
  const getUserActiveOrderProducts = async () => {
    if (userProfile?.id) {
      const partialOrderProds = await (
        await getPartialOrderProducts(userProfile.id)
      ).filter(
        (itm) =>
          itm.partial_order.status !==
            $Enums.OrderStatus.ORDER_PLACED_PAYMENT_CANCELLED &&
          itm.partial_order.status !==
            $Enums.OrderStatus.ORDER_PLACED_PAYMENT_PENDING
      );
      setActiveOrderSubscriptionPlan(userProfile.stripe_price_id);
      setActiveOrderProducts(
        setOrderToCartItems(
          partialOrderProds.filter(
            (itm) =>
              !itm.partial_return_id &&
              //@ts-ignore
              itm.product_item?.status === $Enums.ProductItemStatus.CUSTOMER
          )
        )
      );

      setUpcomingOrderProducts(
        setOrderToCartItems(
          partialOrderProds.filter(
            (itm) =>
              !itm.partial_return_id &&
              //@ts-ignore
              (itm.product_item?.status === $Enums.ProductItemStatus.HOLD ||
                //@ts-ignore
                itm.product_item?.status ===
                  $Enums.ProductItemStatus.PROCESSING_ORDER)
          )
        )
      );

      setPreviousOrderProducts(
        setOrderToCartItems(
          partialOrderProds.filter(
            (itm) =>
              itm.partial_return_id &&
              itm.partial_return.status !==
                $Enums.OrderStatus.ORDER_FINALISED &&
              //@ts-ignore
              itm.product_item?.status === $Enums.ProductItemStatus.RETURNING
          )
        )
      );

      return partialOrderProds.filter((itm) => !itm.partial_return_id);
    }
  };

  return (
    <UserContext.Provider
      value={{
        session,
        user,
        authorize,
        userProfile,
        refreshUserProfile,
        signOut,
        toggleProductFavorite,
        userFavorites,
        getUserActiveOrderProducts,
        activeOrderProducts,
        previousOrderProducts,
        upcomingOrderProducts,
        getUserProfile,
        cancelUserOrder,
        activeOrderSubscriptionPlan,
        userSubscribed,
      }}
      {...props}
    />
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};
