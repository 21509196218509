import React, { useState, useRef, useEffect } from "react";
import Image from "next/image";
import * as Yup from "yup";
import classNames from "classnames";
import Link from "next/link";

import Props from "./types";
import Section from "../../section/Section";
import Button from "../../button/Button";
import SimpleInput from "../../input/SimpleInput";
import ICONS from "../../constants/icons";
import FORMS from "../../constants/forms";
import { addSubscriber } from "../../../modules/@klaviyo";
import { KlaviyoListCodes } from "../../../commonTypes";
import customImageLoader from "../../../utils/imageloader";

import styles from "./newsletter.module.css";

const NewsletterSlice = ({
  slice,
  settings,
  pageType,
  socials = false,
}: Props): JSX.Element => {
  const [success, setSuccess] = useState(null);

  const title = slice?.primary.title[0].text;
  const bodyText = slice?.primary.bodyText[0].text;
  const buttonText = slice?.primary.buttonText;
  const image = slice?.primary.image;

  const socialData = settings?.data?.slices4 && settings?.data?.slices4[0];
  const titleSocial = socialData?.primary.title[0].text;
  const bodyTextRegular = socialData?.primary.bodyText[0].text;
  const bodyTextSocial = socialData?.primary.socialBodyText[0].text;
  const buttonTextSocial = socialData?.primary.buttonText;
  const facebookLink = socialData?.items[0]?.socialLink.url;
  const instagramLink = socialData?.items[1]?.socialLink.url;
  const tiktokLink = socialData?.items[2]?.socialLink.url;

  const handleSubmit = (values) => {
    addSubscriber(values.email, {
      ...values,
      list: KlaviyoListCodes.NEWSLETTER,
    }).then(() => {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3200);
    });
  };

  const SubmitButton = () => {
    return (
      <div className={styles.button}>
        <Button
          disabled={success}
          type="submit"
          text={success ? "Email subscribed!" : buttonText || buttonTextSocial}
        />
      </div>
    );
  };

  const formData = {
    ...FORMS.NEWSLETTER,
    handleSubmit: handleSubmit,
    submitButton: SubmitButton,
  };

  const SubscribeSection = ({}): JSX.Element => {
    return (
      <div className={styles.container}>
        <div className={styles.title}>{title || titleSocial}</div>
        <div
          className={
            !socialData
              ? styles.newsletterContainer
              : styles.newsletterContainerSocial
          }
        >
          <div className={styles.formInfo}>
            <div className={styles.bodyText}>{bodyText || bodyTextRegular}</div>
            <div className={styles.inputContainer}>
              <SimpleInput data={formData} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //if pageType is type home or uid is how-it-works, don't render
  if (pageType?.type === "home" || pageType?.uid === "how-it-works") {
    return <></>;
  }

  //if newsletter with socials needs to render
  if (socials) {
    return (
      <div id="newsletter">
        <Section
          bgColor="var(--colorWestar)"
          columnReverse={true}
          minHeight={"490"}
        >
          <SubscribeSection />
          <div className={styles.containerRight}>
            <div className={styles.title}>{title || titleSocial}</div>
            <div className={styles.newsletterContainerSocial}>
              <div className={styles.formInfo}>
                <div className={styles.bodyText}>
                  {bodyTextSocial || bodyText}
                </div>
                <div className={styles.socialsContainer}>
                  {instagramLink && (
                    <div className={styles.socialIcon}>
                      <a href={instagramLink} target="_blank" rel="noreferrer">
                        <ICONS.INSTAGRAM size={17} />
                      </a>
                    </div>
                  )}
                  {tiktokLink && (
                    <div className={styles.socialIcon}>
                      <a href={tiktokLink} target="_blank" rel="noreferrer">
                        <ICONS.TIKTOK size={17} />
                      </a>
                    </div>
                  )}
                  {facebookLink && (
                    <div className={styles.socialIcon}>
                      <a href={facebookLink} target="_blank" rel="noreferrer">
                        <ICONS.FACEBOOK size={17} />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Section>
      </div>
    );
  } else {
    return (
      <div id="newsletter">
        <Section bgColor="var(--colorWestar)" columnReverse={true}>
          <SubscribeSection />
          <div
            className={classNames(
              styles.newsletterContainer,
              styles.newsletterContainerImage
            )}
          >
            <div className={styles.image}>
              <Image
                width={image.dimensions.width}
                height={image.dimensions.height}
                src={image.url}
                alt="Display image"
                loading="eager"
                layout="intrinsic"
                loader={customImageLoader}
              ></Image>
            </div>
          </div>
        </Section>
      </div>
    );
  }
};

export default NewsletterSlice;
