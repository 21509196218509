import React, { useState, useEffect, useContext, useRef } from "react";
import Image from "next/image";
import Link from "next/link";

import { SimpleProductCardProps } from "./types";
import ICONS from "../constants/icons";
import IMAGES from "../constants/images";
import { useShopContext } from "../../context/ShopContext";
import customImageLoader from "../../utils/imageloader";

import styles from "./productCard.module.css";
import classNames from "classnames";

const SimpleProductCard = ({
  cartItem,
  onItemRemove,
}: // handlePlusClick,
// handleHeartClick,
SimpleProductCardProps): JSX.Element => {
  const { removeItemFromCart } = useShopContext();

  const handleRemove = () => {
    onItemRemove({ cartItem });
  };

  return (
    <div className={styles.basketContainer}>
      <div
        className={classNames(styles.basketImageContainer, {
          [styles.basketImageContainerUnavilable]: cartItem.unavailable,
        })}
      >
        <Link href={`/products/${cartItem?.product?.slug}`}>
          <a>
            {cartItem.keptItem && (
              <span className={styles.productTag}>In your Wardrobe</span>
            )}
            {cartItem.unavailable && (
              <span className={styles.basketImageUnavailable}>Unavailable</span>
            )}
            <Image
              src={
                cartItem.product.images && cartItem.product.images.length > 0
                  ? cartItem.product.images[0]
                  : IMAGES.PRODUCTDEFAULT
              } // overrides with default image if image is null or none provided
              height={1}
              width={1}
              loading="eager"
              layout="responsive"
              alt="cartItem.product image"
              loader={customImageLoader}
            />
          </a>
        </Link>
      </div>
      <div className={styles.basketInfoContainer}>
        <div className={styles.basketName}>{cartItem?.product?.name}</div>
        <div className={styles.basketSize}>
          Size: {cartItem.selected_variant?.size?.name}
        </div>
      </div>
      {onItemRemove && (
        <div className={styles.closeButton} onClick={handleRemove}>
          <ICONS.CLOSE size={16} />
        </div>
      )}
    </div>
  );
};

export default SimpleProductCard;
