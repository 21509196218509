import React, { useState, useContext, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";

import { useShopContext } from "../../context/ShopContext";
import Button from "../button/Button";
import SimpleProductCard from "../product/SimpleProductCard";
import { ModalContext } from "../../context/ModalContext";

import styles from "./basket.module.css";

const Basket = () => {
  const [plan, setPlan] = useState(null);
  const [checkoutMessage, setCheckoutMessage] = useState(null);
  const router = useRouter();
  const { cart, removeItemFromCart, subscriptionOption } = useShopContext();
  const { handleModal } = useContext(ModalContext);

  // const { cart, removeItemFromCart, subscriptionOption } = useShopContext();

  // TODO - link basket limit to subscription option

  useEffect(() => {
    setPlan(subscriptionOption);
  }, [subscriptionOption]);

  //TODO - add ability to have multiple of the same item but only remove one of them (give products in cart a unique id as well as their item id?)

  const handleRemoveItemFromBasket = (item) => {
    //takes the selected_variant.id as the ID
    removeItemFromCart(item);
  };

  const handleChangePlan = () => {
    router.push("/collection");
    handleModal({ content: false });
  };

  const handleCheckout = () => {
    if (cart.find((itm) => !!itm.unavailable)) {
      // Cart has unavailable items
      setCheckoutMessage(`Your bag has unavailable items`);
    } else if (cart.length == plan?.limit) {
      //proceed to checkout -->
      router.push("/checkout");
      handleModal({});
    } else if (cart.length > plan?.limit) {
      //disable button, print message saying to remove items or change plan
      setCheckoutMessage(
        `You have ${
          cart.length - plan.limit
        } items more than are available in your plan, consider choosing a new plan or removing some items`
      );
    } else if (plan?.limit) {
      //disable button, print message saying to add more items
      setCheckoutMessage(
        `You still have ${plan?.limit - cart.length} items left to pick`
      );
    } else {
      setCheckoutMessage(`Please pick a subscription plan before checking out`);
    }
  };

  const handleClickCheckoutMessage = () => {
    setCheckoutMessage(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleSection}>
        <div className={styles.titleTop}>
          <div>Summary of wardrobe</div>
          {/* TODO - Add correct numbers of items picked*/}
          <div
            className={classNames({
              [styles.green]: cart.length == plan?.limit,
              [styles.red]: cart.length > plan?.limit,
            })}
          >
            {plan && `${cart.length} out of ${plan?.limit || 0} picked`}
          </div>
        </div>
        {/*TODO - Add correct subscription plan from cart below*/}
        <div>
          {plan
            ? `Subscription ${plan?.plan}`
            : "Select a plan below to get started"}
        </div>
      </div>
      <div className={styles.productSection}>
        {cart &&
          cart.map((item, index) => {
            return (
              <div key={index} className={styles.productItem}>
                <SimpleProductCard
                  cartItem={item}
                  onItemRemove={() => handleRemoveItemFromBasket(item)}
                />
              </div>
            );
          })}
      </div>
      <div className={styles.buttonSection}>
        <Button
          onClick={handleChangePlan}
          text={plan ? "Change plan" : "Select a plan"}
        />
        <Button onClick={handleCheckout} text="Proceed to checkout" />
      </div>
      {checkoutMessage && (
        <div
          onClick={handleClickCheckoutMessage}
          className={styles.checkoutMessage}
        >
          {checkoutMessage}
          {cart.length > plan?.limit && (
            <div className={styles.upgradeButton}>
              <Button onClick={handleChangePlan} text="Upgrade your plan" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Basket;
