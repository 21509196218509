import moment from "moment";
// import { supabase } from "../lib/supabase";
import * as orderDBActions from "../modules/@supabase/order";

import {
  CartItem,
  DeliveryProviderOptions,
  DeliveryStatusTypes,
  Order,
  OrderStatusTypes,
  SubscriptionTypes,
} from "../commonTypes";
import { SubscriptionOption } from "../context/types";
import {
  getOrderProducts,
  insertProductVariantsToOrder,
} from "../modules/@supabase/orderProductVariant";
import { getUserById } from "../modules/@supabase/user";
import {
  findOverlappingOrderVariants,
  getLastActiveOrderFromList,
} from "../utils/orderHelpers";
import { updateProductVariantStockUpdate } from "../modules/@supabase/productVariantStockUpdates";
import { updateProductVariant } from "../modules/@supabase/productVariant";
import { getProductItemsForOrder } from "../modules/@prisma/productItems";
import {
  createPartialOrder,
  updatePartialOrder,
} from "../modules/@prisma/partialOrders";
import { $Enums, partial_order } from "@prisma/client";

export enum OrderErrors {
  ITEMS_NOT_AVAILALE = "Some items are not available anymore",
}

export namespace OrderService {
  export async function updateOrder({
    order_id,
    user_id,
    updatedData,
  }: {
    order_id: number;
    user_id: string;
    updatedData: Partial<partial_order>;
  }) {
    return await orderDBActions.updateOrder(order_id, user_id, updatedData);
    // return await updatePartialOrder(order_id, updatedData);
    //TODO: add in return action
    // return await orderDBActions.updateOrder(order_id, user_id, updatedData);
  }

  export async function getOrder({
    order_id,
    user_id,
  }: {
    order_id: number | string;
    user_id: number | string;
  }): Promise<partial_order> {
    return await orderDBActions.getFullOrder(order_id, user_id);
  }

  export async function getByCheckoutId(
    checkoutId: string
  ): Promise<Order | null> {
    //@ts-ignore
    return await orderDBActions.getOrderByCheckoutId(checkoutId);
  }

  type UpdateVariantQuantities = {
    order_id: number;
    user_id: number | string;
    addStock?: boolean;
    ignoreUpdate?: boolean;
  };

  export async function updateVariantQuantitiesForOrder({
    order_id,
    user_id,
    addStock, // if true, adds stock, if false, removes stock
    ignoreUpdate,
  }: UpdateVariantQuantities) {
    const value = addStock ? 1 : -1;
    // Grab the order
    const orderProducts: any = await getOrderProducts(order_id);
    // grab the user
    const user = await getUserById(user_id);

    // Find the currently active order
    const currentOrder = user.order.find((ord) => ord.id === order_id);
    const prevOrder = getLastActiveOrderFromList(user.order, order_id);

    let variantQuantityAdjustmentsById = {};
    let variantQuantityById = {};

    orderProducts.forEach((orderProduct) => {
      // List the number of stock is included for a particular variant (relevant when more then 1 of variant is in basket)
      variantQuantityAdjustmentsById[orderProduct.product_variant.id] =
        (variantQuantityAdjustmentsById[orderProduct.product_variant.id] || 0) +
        1;
      // Create list of total stock
      variantQuantityById[orderProduct.product_variant.id] =
        orderProduct.product_variant.stock;
    });

    const { overlappingVariants, uniqueVariants } =
      findOverlappingOrderVariants(currentOrder, prevOrder);

    console.log(
      `[VARIANT UPDATE - IGNORE] ignore following variants for order ${order_id}`,
      overlappingVariants
    );
    console.log(
      `[VARIANT UPDATE - INITIATE UPDATE] start variant update for order ${order_id}`,
      uniqueVariants
    );

    for (let index = 0; index < uniqueVariants.length; index++) {
      const prodVarId = uniqueVariants[index];

      const newStockVal =
        variantQuantityById[prodVarId] +
        variantQuantityAdjustmentsById[prodVarId] * value;
      let updatedProductVariant;

      console.log("[VARIANT UPDATE - INITIATE UPDATE] INSIDE FOR", {
        prodVarId,
        newStockVal,
        ignoreUpdate,
      });

      try {
        if (!ignoreUpdate) {
          console.log("start updating product variant", prodVarId);
          // Wasnt in the previous round, so can just be mentioned
          updatedProductVariant = await updateProductVariant(prodVarId, {
            stock: newStockVal,
          });
          console.log("finish updating product variant", prodVarId);

          // log the change in quantity
          await updateProductVariantStockUpdate(
            prodVarId,
            order_id,
            newStockVal,
            variantQuantityById[prodVarId]
          );

          console.log("finish updating product variant stock", prodVarId);
        }

        console.log(
          `[VARIANT-UPDATE - SUCCESS] update product variant | DB UPDATE ${
            ignoreUpdate ? "IGNORED" : "SUCCESSFULL"
          }, updated PRODVAR${prodVarId} in ORD${order_id} to ${newStockVal}`,
          updatedProductVariant || { update: "ignored" }
        );
      } catch (err) {
        console.log("ERR syncing products", err, prodVarId);
      }
    }

    return orderProducts;
  }
}
