/**
 * All Database interactions for the products table
 */

import * as Sentry from "@sentry/browser";
import { supabase } from "../../lib/supabase";
import { Order, CartItem, Product } from "../../commonTypes";

export const getProductById = async (
  id: number
): Promise<Product | undefined> => {
  const { data, error } = await supabase
    .from("products")
    .select(`*`)
    .eq("id", id.toString());

  return data[0];
};

export const getAllProducts = async (): Promise<Product[]> => {
  // TODO: make this type the productDB one
  try {
    const { data, error } = await supabase
      .from("products")
      .select(
        `
        id,
        name,
        created_at,
        updated_at,
        published_at,
        description,
        images,
        product_reference,
        slug,
        accordion1_title,
        accordion1,
        accordion2_title,
        accordion2,
        accordion3_title,
        accordion3,
        rrp,
        product_tags (
          tags (
            id,
            name,
            type,
            slug
        )
        ),
        product_variants (
          id,
          size:size_id (
            id,
            name,
            slug,
            order
          ),
          color: color_id (
            id,
            name,
            slug,
            metadata,
            order
          ),
          sku,
          stock,
          deleted_at
        )`
      )
      .is("deleted_at", null)
      .not("published_at", "is", null)
      .order("order");

    if (error) {
      Sentry.captureException(error);
      throw error;
    }

    const datahiddenDeletedVariants = data.map((product) => {
      const filteredVariants = product.product_variants.filter((variant) => {
        if (!variant.deleted_at) {
          return variant;
        }
      });
      product.product_variants = filteredVariants;
      return product;
    });

    return datahiddenDeletedVariants;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
