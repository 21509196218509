import ORLogo from "../../media/icons/ORLogo";
import BurgerLines from "../../media/icons/BurgerLines";
import HeartIcon from "../../media/icons/HeartIcon";
import CloseIcon from "../../media/icons/CloseIcon";
import ArrowIcon from "../../media/icons/ArrowIcon";
import FacebookIcon from "../../media/icons/FacebookIcon";
import InstagramIcon from "../../media/icons/InstagramIcon";
import TiktokIcon from "../../media/icons/TiktokIcon";
import PlusIcon from "../../media/icons/PlusIcon";
import ArrowUp from "../../media/icons/ArrowUp";
import BackIcon from "../../media/icons/BackIcon";
import SearchIcon from "../../media/icons/SearchIcon";

const ICONS = {
  ORLOGO: ORLogo,
  BURGERLINES: BurgerLines,
  HEART: HeartIcon,
  CLOSE: CloseIcon,
  ARROW: ArrowIcon,
  BACK: BackIcon,
  FACEBOOK: FacebookIcon,
  INSTAGRAM: InstagramIcon,
  TIKTOK: TiktokIcon,
  PLUS: PlusIcon,
  ARROWUP: ArrowUp,
  SEARCH: SearchIcon,
};

export default ICONS;
