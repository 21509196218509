//API functions for shop and product
import { supabase } from "../../lib/supabase";
import { GiftingOrder } from "../../commonTypes";
import * as Sentry from "@sentry/browser";

export const insertGiftingOrder = async (order: GiftingOrder) => {
  try {
    const { data, error } = await supabase
      .from("order_gifting")
      .insert([order]);
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};

export const updateGiftingOrder = async (
  order_id: number | string,
  updatedData: GiftingOrder
) => {
  try {
    const { data, error } = await supabase
      .from("order_gifting")
      .update(updatedData)
      .match({ id: order_id });
    console.log("ERR", error);
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};

export const getGiftingOrder = async (order_id): Promise<GiftingOrder[]> => {
  try {
    const { data, error } = await supabase
      .from("order_gifting")
      .select(`*`)
      .match({ id: order_id });

    if (error) {
      console.log("ERR", error);
    }
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};
