import React, { useContext, useState } from "react";

import { useUser } from "../../context/UserContext";
import { ModalContext } from "../../context/ModalContext";
import SimpleInput from "../input/SimpleInput";
import Button from "../button/Button";
import FORMS from "../constants/forms";
import { LoginValues } from "./types";

import styles from "./authForm.module.css";
import { addSubscriber } from "../../modules/@klaviyo";
import { signUpWithEmail } from "../../modules/@supabase/auth";
import { KlaviyoListCodes } from "../../commonTypes";

const SignUpForm = () => {
  const [error, setError] = useState(null);
  const { authorize } = useUser();
  const { handleModal } = useContext(ModalContext);

  // TODO - make sure modal closes on signup once avalue has been returned
  const handleSignUp = async ({
    email,
    password,
  }: LoginValues): Promise<void> => {
    try {
      const result = await signUpWithEmail({ email, password });
      if (result.error) {
        setError(result.error.message);
        return;
      } else {
        authorize();
      }
      await addSubscriber(email, { list: KlaviyoListCodes.NEWSLETTER }); // Add new user to customers list
      handleModal({ content: false, withImage: false });
    } catch (error) {
      console.log("error", error);
      setError(error.message);
    }
    return;
  };

  const handlePasswordReset = ({}) => {};

  const SubmitButton = (): JSX.Element => {
    return (
      <div className={styles.button}>
        <Button type="submit" text={"Continue"} />
      </div>
    );
  };

  const formData = {
    ...FORMS.SIGNUP,
    handleSubmit: handleSignUp,
    submitButton: SubmitButton,
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modalTitle}>Register</div>
        <div className={styles.modalBody}>
          Register for an OR account, please enter your email and choose a
          password below
        </div>
        <SimpleInput data={formData} />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </>
  );
};

export default SignUpForm;
