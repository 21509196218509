import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import classNames from "classnames";
import { useRouter } from "next/router";

import { useShopContext } from "../../context/ShopContext";
import { ModalContext } from "../../context/ModalContext";
import { useUser } from "../../context/UserContext";
import { useAppContext } from "../../context/AppContext";

import LoginForm from "../auth/LoginForm";
import PostcodePopup from "../postcodePopup/PostcodePopup";
import RegularNewsletter from "../popups/RegularNewsletter";
import Basket from "../basket/Basket";
import ICONS from "../constants/icons";
import {
  useDesktopMediaQuery,
  useOutsideClickHandler,
} from "../../utils/helpers";
import InternalLink from "../InternalLink";

import styles from "./header.module.css";

let scrollPosition = typeof window !== "undefined" && window.scrollY;

const Header = ({ settings }): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [cartNumber, setCartNumber] = useState(0);
  const [triggerNewsletterPopup, setTriggerNewsletterPopup] = useState(false);
  const [newsletterShown, setNewsletterShown] = useState(null);
  const { userProfile, signOut, refreshUserProfile, user } = useUser();
  const { handleModal, modal } = useContext(ModalContext);
  const { popupImage } = useAppContext();

  const { cart, subscriptionOption } = useShopContext();

  const burgerRef = useRef(null);
  const router = useRouter();
  const headerNavItems = settings?.data?.slices1;
  const shopText = headerNavItems[0].primary.itemName;
  const loginText = headerNavItems[1].primary.itemName;
  const cartText = headerNavItems[2].primary.itemName;
  const welcomeMessage = userProfile?.firstName
    ? `Hi ${userProfile?.firstName}`
    : "Hi there";

  const scrollHandler = useCallback(() => {
    if (window.scrollY > scrollPosition && headerVisible) {
      setHeaderVisible(false);
    } else if (window.scrollY < scrollPosition && !headerVisible) {
      setHeaderVisible(true);
    }

    scrollPosition = window.scrollY;
  }, [headerVisible]);

  useEffect(() => {
    let timer;
    if (userProfile) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
      timer = setTimeout(() => {
        setTriggerNewsletterPopup(true);
      }, 5000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [userProfile, setTriggerNewsletterPopup]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.addEventListener("scroll", scrollHandler);
      return () => {
        document.removeEventListener("scroll", scrollHandler);
      };
    }
  }, [headerVisible, scrollHandler]);

  useEffect(() => {
    if (
      triggerNewsletterPopup &&
      !newsletterShown &&
      !router.query.subscribe2023 &&
      !modal
    ) {
      if (!user && !userProfile) {
        setNewsletterShown(true);
      }
      setTimeout(() => {
        setTriggerNewsletterPopup(false);
      }, 300);
    }
  }, [
    user,
    userProfile,
    popupImage,
    newsletterShown,
    triggerNewsletterPopup,
    popupImage,
  ]);

  useEffect(() => {
    setCartNumber(cart?.length);
  }, [cart]);

  const handleCartClick = () => {
    handleModal({ content: <Basket />, basket: true });
  };

  const toggleBurger = () => {
    setMenuOpen(!menuOpen);
  };

  const closeBurger = () => {
    setMenuOpen(false);
  };

  useOutsideClickHandler(burgerRef, closeBurger);

  // TODO - add correct hrefs to <Link> components (contact & styled for you)
  return (
    <>
      {newsletterShown && !triggerNewsletterPopup && <RegularNewsletter />}
      <div
        className={classNames(styles.header, {
          [styles.headerVisible]: headerVisible,
        })}
      >
        <div className={styles.headerContent} ref={burgerRef}>
          <div className={styles.headerLeft}>
            <div
              className={classNames(styles.burgerIcon, {
                // [styles.burgerIconOpen]: menuOpen,
              })}
              onClick={toggleBurger}
            >
              <div className={styles.burgerIconLine} />
              <div className={styles.burgerIconLine} />
              <div className={styles.burgerIconLine} />
              {/* {menuOpen ? (
              <div className={styles.closeIcon}>
                <ICONS.CLOSE size={22} />
              </div>
            ) : (
              <ICONS.BURGERLINES size={15} />
            )} */}
            </div>
            <div
              className={classNames(styles.burgerOverlay, {
                [styles.burgerOverlayActive]: menuOpen,
              })}
              onClick={closeBurger}
            />
            <div
              onClick={toggleBurger}
              className={classNames(styles.burger, {
                [styles.burgerOpen]: menuOpen,
                [styles.burgerOpenHeaderHidden]: menuOpen && !headerVisible,
              })}
            >
              <div
                className={classNames(
                  styles.burgerIcon,
                  styles.burgerIconBurger,
                  {
                    [styles.burgerIconOpen]: menuOpen,
                  }
                )}
              >
                <div className={styles.burgerIconLine} />
                <div className={styles.burgerIconLine} />
                <div className={styles.burgerIconLine} />
              </div>
              {menuOpen && (
                <>
                  <InternalLink href="/collection">
                    <a className={styles.burgerItem}>Shop</a>
                  </InternalLink>
                  <InternalLink href="/about">
                    <a className={styles.burgerItem}>About us</a>
                  </InternalLink>
                  <InternalLink href="/how-it-works">
                    <a className={styles.burgerItem}>How it works</a>
                  </InternalLink>
                  <InternalLink href="/contact">
                    <a className={styles.burgerItem}>Contact</a>
                  </InternalLink>
                  <InternalLink href="/gifting">
                    <a className={styles.burgerItem}>Gift cards</a>
                  </InternalLink>
                  {loggedIn ? (
                    <>
                      <InternalLink href="/account">
                        <a className={styles.burgerItem}>My account</a>
                      </InternalLink>
                      <a
                        className={styles.burgerItem}
                        onClick={() => {
                          signOut();
                          router.push("/");
                        }}
                      >
                        Logout
                      </a>
                    </>
                  ) : (
                    <a
                      onClick={() =>
                        handleModal({
                          content: <LoginForm />,
                          withImage: false,
                        })
                      }
                      className={styles.burgerItem}
                    >
                      Login
                    </a>
                  )}
                  <InternalLink href="/terms">
                    <a className={styles.burgerItemSmall}>Terms & Conditions</a>
                  </InternalLink>
                </>
              )}
            </div>
            <InternalLink href="/collection">
              <a className={styles.shopNow}>{shopText}</a>
            </InternalLink>
          </div>
          <div className={styles.headerMiddle}>
            <div className={styles.ORLogo}>
              <InternalLink href="/">
                <a>
                  <ICONS.ORLOGO size={44} />
                </a>
              </InternalLink>
            </div>
          </div>

          <div className={styles.headerRight}>
            {loggedIn ? (
              <div className={styles.dropdown}>
                <div className={styles.loginText}>{welcomeMessage}</div>
                <div className={styles.dropdownLinks}>
                  <InternalLink href="/account">
                    <a>My account</a>
                  </InternalLink>
                  <a
                    onClick={() => {
                      signOut();
                      router.push("/");
                    }}
                  >
                    Logout
                  </a>
                </div>
              </div>
            ) : (
              <a
                onClick={() =>
                  handleModal({
                    content: <LoginForm />,
                    withImage: false,
                  })
                }
                className={styles.loginText}
              >
                {loginText}
              </a>
            )}
            <InternalLink href="/account">
              <a className={styles.heartIcon}>{<ICONS.HEART size={30} />}</a>
            </InternalLink>
            <div className={styles.cartContainer} onClick={handleCartClick}>
              <div className={styles.cartText}>{cartText}</div>
              <div className={styles.cartIcon}>{cartNumber}</div>
            </div>
          </div>
        </div>

        {(router.route === "/collection" ||
          router.route === "/products/[slug]") && (
          <div className={styles.subscriptionBanner}>
            <div className={styles.subscriptionBannerLeft}>
              {router.route === "/products/[slug]" && (
                <div
                  className={styles.backBtn}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                      router.back();
                    }, 10);
                  }}
                >
                  <ICONS.BACK />
                  Back
                </div>
              )}
            </div>
            {subscriptionOption && (
              <div className={styles.subscriptionBannerRight}>
                <p
                  onClick={handleCartClick}
                  className={styles.subscriptionBannerOption}
                >
                  Subscription {subscriptionOption.plan}
                </p>
                <div className={styles.subscriptionBannerDivider} />
                <p
                  onClick={handleCartClick}
                  className={classNames({
                    [styles.limitError]: cart.length > subscriptionOption.limit,
                  })}
                >
                  {cart.length} of {subscriptionOption.limit} picked
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
