import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  FC,
  useEffect,
} from "react";
import { Product, Tag, VariantOption, CartItem } from "../commonTypes";
import Basket from "../components/basket/Basket";

import { ModalContext } from "../context/ModalContext";

import { ShopContextInterface, SubscriptionOption } from "./types";
import { ProductService } from "../services/product.service";
import { generateLocalStock, LocalStock } from "../utils/productFunctions";
import { useUser } from "./UserContext";
import { getAllTags } from "../modules/@supabase/tags";
import { getAllVariantOptions } from "../modules/@supabase/variantOptions";

const ShopContext = createContext<ShopContextInterface | null>(null);

const ShopProvider: FC<ShopContextInterface> = ({
  children,
}: ShopContextInterface) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [cart, setCart] = useState<CartItem[] | null>([]);

  const [productTags, setProductTags] = useState<Tag[]>([]);
  const [variantOptions, setVariantOptions] = useState<VariantOption[]>([]);

  const { activeOrderProducts, getUserActiveOrderProducts } = useUser();

  const [subscriptionOption, setSubscriptionOption] = useState(null);

  const { productsLocalStock, cartLocalStock } = useMemo<LocalStock>(() => {
    return generateLocalStock(cart, products, activeOrderProducts);
  }, [products, cart, activeOrderProducts]);

  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    refreshProducts();
    getProductTags(); // Not sure if we need to do this everywhere or if we should just trigger on collection?
    getVariantOptions(); // Not sure if we need to do this everywhere or if we should just trigger on collection?

    // get cart from local storage
    const storedCart =
      typeof window !== "undefined" && localStorage.getItem("cart");

    //get subscription from local storage
    const storedSubscriptionOption =
      typeof window !== "undefined" &&
      localStorage.getItem("subscriptionOptionNew");

    if (storedCart) {
      const newCart = JSON.parse(storedCart);
      setCart(newCart);
    }

    storedSubscriptionOption &&
      setSubscriptionOption(JSON.parse(storedSubscriptionOption));
  }, []);

  //store cart in localstorage
  useEffect(() => {
    typeof window !== "undefined" &&
      localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  //store subscription plan in localstorage
  useEffect(() => {
    typeof window !== "undefined" &&
      localStorage.setItem(
        "subscriptionOptionNew",
        JSON.stringify(subscriptionOption)
      );
  }, [subscriptionOption]);

  const refreshProducts = async (): Promise<LocalStock> => {
    let newProds = [];
    try {
      newProds = await ProductService.getProducts();
      setProducts(newProds);
      setLoading(false);
    } catch (err) {
      console.log("err", err);
    }

    // Return updated version in case you need it outside of the memo
    return generateLocalStock(cart, newProds, activeOrderProducts);
  };

  const getProductTags = async () => {
    const tags: Tag[] = await getAllTags();
    setProductTags(tags);
  };
  const getVariantOptions = async () => {
    const variantOpt: VariantOption[] = await getAllVariantOptions();
    setVariantOptions(variantOpt);
  };

  const addItemToCart = (cartItem: CartItem) => {
    const selected_variant = cartItem.selected_variant;

    if (cartItem.keptItem) {
      // if item is kept from previous rotation, then stock is not considered/updated
      setCart((cart) => [...cart, cartItem]);
    } else if (selected_variant && selected_variant.stock > 0) {
      setCart((cart) => [...cart, cartItem]);

      // updateStockLevelForCartItem(cartItem, products, true);

      //if cart is full then open basket
      if (subscriptionOption?.limit == cart.length + 1) {
        handleModal({ content: <Basket />, basket: true });
      }
    }
  };

  const rotateCart = (products: CartItem[]) => {
    setCart(products); // Replaces current cart with current wardrobe
  };

  const clearCart = () => {
    setCart([]);
  };

  const removeItemFromCart = (item: CartItem) => {
    const uid = item.uid;

    setCart(
      (cart || []).filter((item) => {
        return item.uid !== uid;
      })
    );
  };

  const applySubscriptionOption = ({
    plan,
    limit,
    price,
    stripeId,
  }: SubscriptionOption) => {
    setSubscriptionOption({ plan, limit, price, stripeId });
  };

  return (
    <ShopContext.Provider
      value={{
        products: productsLocalStock,
        cart: cartLocalStock,
        refreshProducts,
        addItemToCart,
        removeItemFromCart,
        productTags,
        loading,
        variantOptions,
        subscriptionOption,

        applySubscriptionOption,
        rotateCart,
        clearCart,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

export const useShopContext = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    throw new Error(
      "useShopContext must be used within a ShopContextProvider."
    );
  }
  return context;
};

export { ShopContext, ShopProvider };
