import de from "date-fns/esm/locale/de/index.js";
import VALIDATION from "./validation";

const newsletterForm = {
  inputNames: { email: "email" },
  placeholderText: {
    email: "Email",
  },
  inputTypes: {
    email: "text",
  },
  initialValues: {
    email: "",
  },
  validationSchema: VALIDATION.EMAIL,
};
const postcodeNewsletterForm = {
  inputNames: {
    email: "email",
    name: "name",
    postcode: "postcode",
    littleDOB: "littleDOB",
  },
  placeholderText: {
    email: "Email",
    name: "Name",
    postcode: "Postcode",
    littleDOB: "little one’s D.O.B",
  },
  inputTypes: {
    email: "text",
    name: "text",
    postcode: "text",
    littleDOB: "text",
  },
  initialValues: {
    email: "",
  },
  validationSchema: VALIDATION.EMAIL,
};

const loginForm = {
  inputNames: { email: "email", password: "password" },
  placeholderText: {
    email: "your@email.com",
    password: "Password",
  },
  inputTypes: {
    email: "text",
    password: "password",
  },
  initialValues: {
    email: "",
    password: "",
  },
  validationSchema: VALIDATION.LOGIN,
};

const signupForm = {
  inputNames: {
    email: "email",
    password: "password",
    password2: "password2",
  },
  placeholderText: {
    email: "your@email.com",
    password: "Password",
    password2: "Please re-enter your password",
  },
  inputTypes: {
    email: "text",
    password: "password",
    password2: "password",
  },
  initialValues: {
    email: "",
    password: "",
    password2: "",
  },
  validationSchema: VALIDATION.SIGNUP,
};

const resetPasswordForm = {
  inputNames: {
    email: "email",
  },
  placeholderText: {
    email: "your@email.com",
  },
  inputTypes: {
    email: "text",
  },
  initialValues: {
    email: "",
  },
  validationSchema: VALIDATION.RESETPASSWORD,
};

const accountForm = {
  inputNames: {
    title: "title",
    firstName: "firstName",
    lastName: "lastName",
    babyName: "babyName",
    email: "email",
    contactNumber: "contactNumber",
    address1: "address1",
    address2: "address2",
    city: "city",
    postcode: "postcode",
    country: "country",
  },
  placeholderText: {
    title: "Title",
    firstName: "First name*",
    lastName: "Last name*",
    babyName: "Baby's name*",
    email: "Email*",
    contactNumber: "Contact number*",
    address1: "Address line 1*",
    address2: "Address line 2",
    city: "Town / city*",
    postcode: "Postcode*",
    country: "Country*",
  },
  inputTypes: {
    title: "select",
    firstName: "text",
    lastName: "text",
    babyName: "text",
    email: "text",
    contactNumber: "text",
    address1: "text",
    address2: "text",
    city: "text",
    postcode: "text",
    country: "text",
  },
  inputOptions: {
    title: ["Mr", "Mrs", "Dr", "Ms"],
  },
  inputWidth: {
    title: "50%",
    firstName: "50%",
    postcode: "50%",
    country: "50%",
  },
  validationSchema: VALIDATION.ACCOUNT,
};

const postcodeForm = {
  inputNames: { postcode: "postcode" },
  placeholderText: {
    postcode: "Postcode",
  },
  inputTypes: {
    postcode: "text",
  },
  initialValues: {
    postcode: "",
  },
  validationSchema: VALIDATION.POSTCODE,
};

const contactForm = {
  inputNames: {
    firstName: "firstName",
    surname: "surName",
    email: "email",
    subject: "subject",
    message: "message",
    "form-name": "form-name",
  },

  placeholderText: {
    firstName: "First name*",
    surname: "Surname",
    email: "Email*",
    subject: "Subject*",
    message: "Message*",
  },
  inputTypes: {
    firstName: "text",
    surname: "text",
    email: "text",
    subject: "text",
    message: "textarea",
    "form-name": "hidden",
  },
  initialValues: {
    title: "",
    firstName: "",
    surname: "",
    email: "",
    subject: "",
    message: "",
    "form-name": "Contact",
  },
  inputWidth: {
    surname: "50%",
    firstName: "50%",
  },
  validationSchema: VALIDATION.CONTACT,
};

const deliverySignUpForm = {
  inputNames: {
    title: "title",
    firstName: "firstName",
    lastName: "lastName",
    // address1: "address1",
    // address2: "address2",
    // city: "city",
    // postcode: "postcode",
    // country: "country",
    email: "email",
    contactNumber: "contactNumber",
    password: "password",
    password2: "password2",
    termsAccepted: "termsAccepted",
  },
  placeholderText: {
    title: "Title",
    firstName: "First name*",
    lastName: "Last name*",
    // address1: "Address line 1*",
    // address2: "Address line 2",
    // city: "Town / city*",
    // postcode: "Postcode*",
    // country: "Country*",
    email: "Email address*",
    contactNumber: "Phone number",
    password: "Password*",
    password2: "Please re-enter your password*",
    termsAccepted:
      "I confirm that I accept the Conditions of Use and have read and understood the Privacy and Cookie Policy.*",
  },
  inputTypes: {
    title: "select",
    firstName: "text",
    lastName: "text",
    // address1: "text",
    // address2: "text",
    // city: "text",
    // postcode: "text",
    // country: "text",
    email: "text",
    contactNumber: "text",
    password: "password",
    password2: "password",
    termsAccepted: "checkbox",
  },
  initialValues: {
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    // postcode: "",
    // country: "",
    password: "",
    password2: "",
    termsAccepted: "",
  },
  inputOptions: {
    title: ["Mr", "Mrs", "Dr", "Ms"],
  },
  inputWidth: {
    title: "50%",
    firstName: "50%",
    postcode: "50%",
    country: "50%",
  },
  validationSchema: VALIDATION.DELIVERYSIGNUP,
};

const deliveryTermsAccepted = {
  inputNames: {
    title: "title",
    firstName: "firstName",
    lastName: "lastName",
    address1: "address1",
    address2: "address2",
    city: "city",
    postcode: "postcode",
    country: "country",
    contactNumber: "contactNumber",
    // termsAccepted: "termsAccepted",
  },
  placeholderText: {
    title: "Title",
    firstName: "First name*",
    lastName: "Last name*",
    address1: "Address line 1*",
    address2: "Address line 2",
    city: "Town / city*",
    postcode: "Postcode*",
    country: "Country*",
    contactNumber: "Phone Number* - used for delivery updates only",

    // termsAccepted:
    //   "I confirm that I accept the Conditions of Use and have read and understood the Privacy and Cookie Policy.*",
  },
  inputTypes: {
    title: "select",
    firstName: "text",
    lastName: "text",
    address1: "text",
    address2: "text",
    city: "text",
    postcode: "text",
    country: "text",
    contactNumber: "text",

    // termsAccepted: "checkbox",
  },

  inputOptions: {
    title: ["Mr", "Mrs", "Dr", "Ms"],
  },
  inputWidth: {
    title: "50%",
    firstName: "50%",
    postcode: "50%",
    country: "50%",
  },
  validationSchema: VALIDATION.DELIVERY_NO_TERMS,
};

const deliveryForm = {
  inputNames: {
    ...deliveryTermsAccepted.inputNames,
    termsAccepted: "termsAccepted",
  },
  placeholderText: {
    ...deliveryTermsAccepted.placeholderText,

    termsAccepted:
      "I confirm that I accept the Conditions of Use and have read and understood the Privacy and Cookie Policy.*",
  },
  inputTypes: {
    ...deliveryTermsAccepted.inputTypes,
    termsAccepted: "checkbox",
  },
  inputOptions: {
    ...deliveryTermsAccepted.inputOptions,
  },
  inputWidth: {
    ...deliveryTermsAccepted.inputWidth,
  },
  validationSchema: VALIDATION.DELIVERY,
};

const timeForm = {
  inputNames: { time: "time" },
  placeholderText: {
    time: "Time*",
  },
  inputTypes: {
    time: "autoselect",
  },
  initialValues: {
    time: "",
  },
};

const newPassword = {
  inputNames: {
    password: "password",
    password2: "password2",
  },
  placeholderText: {
    password: "Password",
    password2: "Please re-enter your password",
  },
  inputTypes: {
    password: "password",
    password2: "password",
  },
  initialValues: {
    password: "",
    password2: "",
  },
  validationSchema: VALIDATION.NEWPASSWORD,
};

const giftAmount = {
  inputNames: {
    giftAmount: "giftAmount",
  },
  placeholderText: {
    giftAmount: "£ enter any amount",
  },
  inputTypes: {
    giftAmount: "text",
  },
  initialValues: {
    giftAmount: "",
  },
  validationSchema: VALIDATION.GIFTAMOUNT,
};

const giftSender = {
  inputNames: {
    title: "title",
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    phoneNumber: "phoneNumber",
  },
  placeholderText: {
    title: "Title",
    firstName: "First name*",
    lastName: "Last name*",
    email: "Email address*",
    phoneNumber: "Phone number",
  },
  inputTypes: {
    title: "select",
    firstName: "text",
    lastName: "text",
    email: "text",
    phoneNumber: "text",
  },
  initialValues: {
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  inputOptions: {
    title: ["Mr", "Mrs", "Dr", "Ms"],
  },
  inputWidth: {
    title: "50%",
    firstName: "50%",
  },
  validationSchema: VALIDATION.GIFTUSER,
};

const giftRecipient = {
  inputNames: {
    title: "title",
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    phoneNumber: "phoneNumber",
  },
  placeholderText: {
    title: "Title",
    firstName: "First name*",
    lastName: "Last name*",
    email: "Email address*",
    phoneNumber: "Phone number",
  },
  inputTypes: {
    title: "select",
    firstName: "text",
    lastName: "text",
    email: "text",
    phoneNumber: "text",
  },
  initialValues: {
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  inputOptions: {
    title: ["Mr", "Mrs", "Dr", "Ms"],
  },
  inputWidth: {
    title: "50%",
    firstName: "50%",
  },
  validationSchema: VALIDATION.GIFTRECIPIENT,
};

const giftMessage = {
  inputNames: { message: "message" },
  placeholderText: {
    message: "Gift card message...",
  },
  inputTypes: {
    message: "textarea",
  },
  initialValues: {
    message: "",
  },
  validationSchema: VALIDATION.GIFTMESSAGE,
};

const discountCodeForm = {
  inputNames: { code: "code" },
  placeholderText: {
    code: "Enter discount code",
  },
  inputTypes: {
    code: "text",
  },
  initialValues: {
    code: "",
  },
  validationSchema: null,
};

const FORMS = {
  NEWSLETTER: newsletterForm,
  POSTCODE_NEWSLETTER: postcodeNewsletterForm,
  LOGIN: loginForm,
  SIGNUP: signupForm,
  RESETPASSWORD: resetPasswordForm,
  ACCOUNT: accountForm,
  POSTCODE: postcodeForm,
  CONTACT: contactForm,
  DELIVERY: deliveryForm,
  DELIVERYSIGNUP: deliverySignUpForm,
  DELIVERYTERMSACCEPTED: deliveryTermsAccepted,
  TIME: timeForm,
  NEWPASSWORD: newPassword,
  GIFTAMOUNT: giftAmount,
  GIFTSENDER: giftSender,
  GIFTRECIPIENT: giftRecipient,
  GIFTMESSAGE: giftMessage,
  DISCOUNTCODE: discountCodeForm,
};

export default FORMS;
