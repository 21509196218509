import React, { useState, createContext } from "react";

import { ModalProps, HandleModalProps } from "../components/modal/types";

export const ModalContext = createContext<ModalProps | null>(null);

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);
  const [withImage, setWithImage] = useState(null);
  const [basket, setBasket] = useState(null);

  //! prelaunch
  const [fullScreen, setFullScreen] = useState(null);
  //! end prelaunch

  const modal = !!modalContent;

  const handleModal = ({
    content = false,
    withImage = false,
    basket = false,
    fullScreen = false,
    extendedHeight = false,
  }: HandleModalProps): ModalProps["handleModal"] => {
    setModalContent(content);
    setWithImage(withImage);
    setBasket(basket);
    setFullScreen(fullScreen);
    return;
  };

  return (
    <>
      <ModalContext.Provider
        value={{
          modal,
          handleModal,
          modalContent,
          withImage,
          basket,
          fullScreen,
        }}
      >
        {children}
      </ModalContext.Provider>
    </>
  );
};
