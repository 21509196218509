import React, { useState, useEffect, useLayoutEffect } from "react";
import { useMediaQuery } from "react-responsive";

var navigator = require("global/window").navigator;

const useOutsideClickHandler = (ref, callback: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

const useBreakpoint = (query, serverFallback = true) => {
  const isBrowser = Boolean(process.browser);
  const [browserFlushed, setBrowserFlushed] = useState(false);
  const isMobile = useMediaQuery(query);
  const canUseDOM = typeof window !== "undefined";
  const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;

  useIsomorphicLayoutEffect(() => setBrowserFlushed(true), []);

  if (isBrowser && browserFlushed) {
    return isMobile;
  }
  return serverFallback;
};

const isIOS =
  typeof navigator !== "undefined" &&
  (/iPad|iPhone|iPod/.test(navigator.userAgent || "") ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)); // iPad iOS 13

const useMobileMediaQuery = () =>
  useBreakpoint({ query: "(max-width: 460px)" });
const useTabletMediaQuery = () =>
  useBreakpoint({ query: "(min-width: 461px)" });
const useDesktopMediaQuery = () =>
  useBreakpoint({ query: "(min-width: 800px)" });
const useDesktopXLMediaQuery = () =>
  useBreakpoint({ query: "(min-width: 1440px)" });

const getParameterByName = (name: string, url?: string) => {
  if (!url) url = window?.location?.href || "";
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&#]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const findFirst = (arr, cb, searchFromIndex = 0) => {
  for (let i = searchFromIndex; i < arr.length; i++) {
    if (cb(arr[i])) {
      return arr[i];
    }
  }
  return undefined;
};

export {
  useMobileMediaQuery,
  useTabletMediaQuery,
  useDesktopMediaQuery,
  useDesktopXLMediaQuery,
  useOutsideClickHandler,
  isIOS,
  getParameterByName,
  findFirst,
};
