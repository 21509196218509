/**
 * All Database interactions for the product_variants table
 */

import * as Sentry from "@sentry/browser";
import { Tag } from "../../commonTypes";
import { supabase } from "../../lib/supabase";

/**
 * Get All Tags
 * @returns
 */
export const getAllTags = async (): Promise<Tag[]> => {
  try {
    const { data, error } = await supabase.from("tags").select();
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};
