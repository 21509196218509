import React from "react";
import classNames from "classnames";

import Props from "./types";

import styles from "./button.module.css";
import InternalLink from "../InternalLink";

const Button = ({
  text,
  bgColor = "var(--colorBlack)",
  textColor = "var(--textWhite)",
  onClick,
  menu,
  burger,
  type,
  href,
  disabled,
  extraButtonProps = {},
  className = "",
}: Props): JSX.Element => {
  const buttonProps = {
    className: classNames(styles.buttonDetail, className, {
      [styles.menuButton]: menu,
      [styles.burgerButton]: burger,
    }),
    style: {
      color: textColor,
      backgroundColor: bgColor,
      borderColor: bgColor,
    },
    onClick,
    type,
    disabled,
    ...extraButtonProps,
  };

  if (href) {
    return (
      <InternalLink href={href}>
        <a {...buttonProps}>{text}</a>
      </InternalLink>
    );
  }

  return <button {...buttonProps}>{text}</button>;
};

export default Button;
