import { Product, TagTypes } from "../commonTypes";
import * as productsDBActions from "../modules/@supabase/products";

export namespace ProductService {
  /**
   * Internal functions
   */

  function enrichProducts(products: Product[]): Product[] {
    return products.map((product) => {
      let brand = "";

      let filter_tags = product.product_tags.map(({ tags }) => {
        if (tags.type == TagTypes.BRAND) {
          brand = tags.name;
        }
        return tags.slug;
      });

      let totalAvailability = 0;

      let price = 0;
      product.product_variants.forEach((variant) => {
        if (variant.color) {
          filter_tags.push(variant.color.slug);
        }

        if (typeof variant.stock === "object") {
          variant.stock = 0;
        }

        if (variant.stock < 0) {
          // Ensure stock can't go below 0
          variant.stock = 0;
        }

        totalAvailability += variant.stock;

        if (variant.size) {
          filter_tags.push(variant.size.slug);

          if (price < product.rrp) {
            price = product.rrp;
          }
        }
      });

      if (totalAvailability > 0) {
        filter_tags.push("product-available");
      }

      return { ...product, filter_tags, brand, price, totalAvailability };
    });
  }

  /**
   * Can also get products that have been unpublished
   */
  export async function getProductById(id: number): Promise<Product> {
    return productsDBActions.getProductById(id);
  }

  export async function getProducts(): Promise<Product[]> {
    const products = await productsDBActions.getAllProducts();

    // Enrich the products

    return enrichProducts(products);
  }
}
