import React from "react";
import classNames from "classnames";

import Props from "./types";
import { useMobileMediaQuery, useDesktopMediaQuery } from "../../utils/helpers";

import styles from "./section.module.css";

const Section = ({
  minHeight = "400px",
  width,
  bgColor = "var(--colorWhite)",
  children,
  columnReverse,
  disableTabletPadding,
  disableBottomPadding,
}: Props): JSX.Element => {
  const isMobile = useMobileMediaQuery();
  const isDesktop = useDesktopMediaQuery();
  const isTablet = !isMobile && !isDesktop;
  return (
    <div
      className={styles.bgContainer}
      style={{
        // minHeight: minHeight,
        backgroundColor: bgColor,
        width: width,
      }}
    >
      <div
        className={classNames(styles.section, {
          [styles.columnReverse]: columnReverse,
          [styles.tabletPadding]: isTablet && !disableTabletPadding,
          [styles.disableTabletPadding]: !isDesktop && disableTabletPadding,
          [styles.disableBottomPadding]: disableBottomPadding,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Section;
