import React from "react";

import Section from "../section/Section";
import ICONS from "../constants/icons";

import styles from "./logoComponent.module.css";

const LogoComponent = (): JSX.Element => {
  return (
    // <Section minHeight="250px" bgColor="var(--colorWhite">
    <div className={styles.logoContainer}>
      <div className={styles.logo}>
        <ICONS.ORLOGO size={350} />
      </div>
    </div>
    // </Section>
  );
};

export default LogoComponent;
