import React from "react";

const InstagramIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18.027 18.026"
    >
      <g
        id="Group_738"
        data-name="Group 738"
        transform="translate(6045.701 9878.058)"
      >
        <path
          id="Path_128"
          data-name="Path 128"
          d="M665.7,108.817h-12a3.016,3.016,0,0,1-3.013-3.013v-12a3.016,3.016,0,0,1,3.013-3.013h12a3.016,3.016,0,0,1,3.013,3.013v12a3.016,3.016,0,0,1-3.013,3.013m-12-17.04a2.029,2.029,0,0,0-2.026,2.026v12a2.029,2.029,0,0,0,2.026,2.026h12a2.029,2.029,0,0,0,2.026-2.026v-12a2.029,2.029,0,0,0-2.026-2.026Z"
          transform="translate(-6696.384 -9968.849)"
          fill="#fff"
        />
        <path
          id="Path_129"
          data-name="Path 129"
          d="M701.887,146.62a4.625,4.625,0,1,1,3.27-1.355,4.6,4.6,0,0,1-3.27,1.355m0-8.264A3.639,3.639,0,1,0,705.526,142a3.643,3.643,0,0,0-3.639-3.639"
          transform="translate(-6738.575 -10011.041)"
          fill="#fff"
        />
        <path
          id="Path_130"
          data-name="Path 130"
          d="M799.633,108.846a.888.888,0,1,1-.888-.888.888.888,0,0,1,.888.888"
          transform="translate(-6829.695 -9984.399)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default InstagramIcon;
