import React from "react";

const SearchIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15.94 17.932"
    >
      <g
        id="Group_93"
        data-name="Group 93"
        transform="translate(-92.293 -895.834) rotate(8)"
      >
        <g
          id="Ellipse_16"
          data-name="Ellipse 16"
          transform="translate(218 874)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        >
          <circle cx="7" cy="7" r="7" stroke="none" />
          <circle cx="7" cy="7" r="6.5" fill="none" />
        </g>
        <path
          id="Path_57"
          data-name="Path 57"
          d="M9248.2,889.307l3.749,3.849"
          transform="translate(-9018.581 -3.606)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
