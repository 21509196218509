import React from "react";
import Head from "next/head";
import Script from "next/script";
import { PrismicPreview } from "@prismicio/next";
import { repositoryName } from "../prismic";

const SEO = ({ pageData, settings }) => {
  const page = pageData?.page;
  const data = page?.data;
  const seo = settings.data;

  const shareTitle = data?.shareTitle ? data.shareTitle : seo.shareTitle;
  const shareImage = data?.shareImage.url
    ? data.shareImage.url
    : seo.shareImage.url;
  const shareDescription = data?.shareDescription
    ? data.shareDescription
    : seo.shareDescription;

  return (
    <>
      <Head>
        {/* Default cross site meta */}
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta property="og:site_name" content="OR Collective" />
        <meta property="og:type" content="website" />

        {/* Standard Meta */}
        <title>{shareTitle}</title>
        <meta name="description" content={shareDescription}></meta>
        <meta name="image" property="og:image" content={shareImage} />
      </Head>
      <PrismicPreview repositoryName={repositoryName} />
      <Script src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Rv3dgA" />
      <Script
        id="cookieyes"
        src="https://cdn-cookieyes.com/client_data/8493d971393beadf09ebd4b2/script.js"
      />

      <Script
        src="https://www.googletagmanager.com/gtag/js?id=UA-235672348-1"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-235672348-1');
        `}
      </Script>

      <Script id="show-banner" strategy="lazyOnload">
        {/* {`   var _learnq = _learnq || [];`} */}
      </Script>
    </>
  );
};

export default SEO;
