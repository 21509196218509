/**
 * All Database interactions for the order product variant table
 */
import * as Sentry from "@sentry/browser";
import { supabase } from "../../lib/supabase";
import {
  CartItem,
  Order,
  OrderProductData,
  PartialOrderProductItemData,
} from "../../commonTypes";
import { $Enums } from "@prisma/client";

/**
 * Get Order Product Variants
 */
export const getOrderProducts = async (
  orderId
): Promise<OrderProductData[]> => {
  try {
    const { data, error } = await supabase
      .from("order_product_variants")
      .select(
        `*, order_id(id, user_id), product_variant: product_variant_id(*, id,
          size:size_id (
            id,
            name,
            slug
          ),
          color: color_id (
            id,
            name,
            slug,
            metadata
          ),
          sku,
          stock, product: product_id(*))`
      )
      .eq("order_id(id)", orderId.toString());

    if (error) {
      Sentry.captureException(error);
      throw error;
    }
    return data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

/**
 * Get Order Product Variants
 */
export const getPartialOrderProducts = async (
  userId
): Promise<PartialOrderProductItemData[]> => {
  try {
    const { data, error } = await supabase
      .from("user_product_items")
      .select(
        `*, partial_order(status, date), partial_return(status, date), product_variant: product_variant_id(*, id,
          size:size_id (
            id,
            name,
            slug
          ),
          color: color_id (
            id,
            name,
            slug,
            metadata
          ),
          sku,
          stock, product: product_id(*)),  product_item: product_item_id(*)`
      )
      .eq("user_id", userId);

    if (error) {
      Sentry.captureException(error);
      throw error;
    }
    return data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

/**
 * Insert product variants from a list of cart items
 * @returns
 */
export const insertProductVariantsToOrder = async (
  orderId: Order["id"],
  cart: CartItem[]
) => {
  try {
    const { data, error } = await supabase
      .from("order_product_variants")
      .insert(
        cart.map((item) => {
          return {
            order_id: orderId,
            product_variant_id: item.selected_variant?.id,
          };
        })
      );
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};
