import React, { useContext, useState } from "react";

import { useUser } from "../../context/UserContext";
import { ModalContext } from "../../context/ModalContext";
import SimpleInput from "../input/SimpleInput";
import SignUpForm from "./SignUpForm";
import ResetPasswordForm from "./ResetPasswordForm";
import Button from "../button/Button";
import FORMS from "../constants/forms";

import { LoginValues } from "./types";

import styles from "./authForm.module.css";
import { signInWithEmail } from "../../modules/@supabase/auth";

const LoginForm = () => {
  const [error, setError] = useState(null);
  const { handleModal } = useContext(ModalContext);

  const handleLogin = ({ email, password }: LoginValues): void => {
    signInWithEmail({ email, password })
      .then(() => handleModal({ content: false, withImage: false }))
      .catch((error) => {
        console.log("error", error);
        setError(error.message);
      });
  };

  const handlePasswordReset = () => {
    handleModal({
      content: <ResetPasswordForm />,
      withImage: false,
    });
  };

  // TODO - maybe put submit button in it's own component?
  const SubmitButton = (): JSX.Element => {
    return (
      <div className={styles.button}>
        <Button type="submit" text={"Continue"} />
      </div>
    );
  };

  const formData = {
    ...FORMS.LOGIN,
    handleSubmit: handleLogin,
    submitButton: SubmitButton,
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modalTitle}>Log in</div>
        <div className={styles.modalBody}>
          Welcome back. Enter your password to continue.
        </div>
        <SimpleInput data={formData}>
          <div className={styles.forgotPassword} onClick={handlePasswordReset}>
            Forgot your password?
          </div>
        </SimpleInput>
        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.signUpInfo}>
          <div className={styles.signUpText}>New to Or?</div>
          <div
            className={styles.createAccount}
            onClick={() =>
              handleModal({
                content: <SignUpForm />,
                withImage: false,
              })
            }
          >
            Create an account
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
