import { supabase } from "../../lib/supabase";
import * as Sentry from "@sentry/browser";
import { ApiError, Session, User } from "@supabase/supabase-js";

export const signInWithEmail = async ({ email, password }) => {
  const { user, session, error } = await supabase.auth.signIn({
    email,
    password,
  });
  if (error) {
    console.log("error in signInWithEmail:", error);
    throw new Error(error.message);
  } else {
    console.log("User signed in successfully", user);
    return { user: user };
  }
};

export const signUpWithEmail = async ({
  email,
  password,
}): Promise<{
  authUser: User | null;
  user?: any;
  session: Session | null;
  error: ApiError | null;
}> => {
  const { user, session, error } = await supabase.auth.signUp({
    email,
    password,
  });

  let dbUser;

  if (error) {
    Sentry.captureException(error);
  } else {
    const { data, error } = await supabase
      .from("users")
      .insert([{ id: user?.id, email }]);

    dbUser = data[0];
    if (error) {
      Sentry.captureException(error);
    } else {
    }
  }

  return { authUser: user, user: dbUser, session, error };
};

export const resetPassword = async ({ email }) => {
  const { data, error } = await supabase.auth.api.resetPasswordForEmail(email);
  if (error) {
    return { error };
  } else {
    return { data: data };
  }
};

export const setNewPassword = async ({ token, password }) => {
  const { error, data } = await supabase.auth.api.updateUser(token, {
    password: password,
  });
  if (error) {
    return { error };
  } else {
    return { data: data };
  }
};
