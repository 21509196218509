import React, { useContext, useEffect, useState } from "react";

import { ModalContext } from "../../context/ModalContext";
import StandardPopup from "../standardPopup/StandardPopup";
import { useAppContext } from "../../context/AppContext";

const CustomNewsletter = () => {
  const { handleModal } = useContext(ModalContext);
  const { popupData } = useAppContext();
  const [open, setOpen] = useState(false);

  const data = popupData.find((item) => item.popupName === "customNewsletter");

  useEffect(() => {
    !open
      ? handleModal({
          content: (
            <StandardPopup
              popupContent={data}
              logoTop
              waitlist
              newsletterList
            />
          ),
          withImage: true,
          // fullScreen: true,
        })
      : null;
    setOpen(true);
  }, [handleModal, open, data]);

  return <></>;
};

export default CustomNewsletter;
