/**
 * All Database interactions for the product_variants table
 */

import * as Sentry from "@sentry/browser";
import { VariantOption } from "../../commonTypes";
import { supabase } from "../../lib/supabase";

/**
 * Get All variant options
 * @returns
 */
export const getAllVariantOptions = async (): Promise<VariantOption[]> => {
  try {
    const { data, error } = await supabase.from("variant_options").select();
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.log("error:", error.message);
  }
};
