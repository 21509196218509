import * as Sentry from "@sentry/browser";
import { fetchPostJSON } from "../../utils/api-helpers";

export const addSubscriber = async (email: string, extraData?: any) => {
  const data = {
    email: email,
    ...(extraData || {}),
  };
  if (process.env.NEXT_PUBLIC_DEV_MODE === "true") {
    // We're in dev mode, so don't send event
    console.log(
      "[IGNORE add subscriber to KLAVIYO BLOCKED -- DEV MODE]",
      email
    );
    return;
  }
  try {
    return await fetchPostJSON("/api/add-subscriber", data).then((response) =>
      console.log("response", response)
    );
  } catch (err) {
    console.log("error in addSubscriber", err);
    Sentry.captureException(err);
  }
};
