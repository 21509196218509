import randomstring from "randomstring";
import moment from "moment";

import {
  Tag,
  Product,
  TagTypes,
  VariantOption,
  SizeOption,
  ProductVariant,
  VariantOptionTypes,
  DeliveryStatusTypes,
  SubscriptionTypes,
  CartItem,
  OrderProductData,
  OrderStatusTypes,
  Order,
  GiftingOrder,
  BaseProduct,
  PartialOrderProductItemData,
} from "../commonTypes";
import {
  insertGiftingOrder,
  updateGiftingOrder,
} from "../modules/@supabase/orderGifting";
import { Filter } from "../components/productFilters/types";

const getColorOptions = ({ product }): VariantOption[] => {
  return product.product_variants.map((variant) => {
    return variant.color;
  });
};

const getSizeOptions = ({ product }): VariantOption[] => {
  return product.product_variants.map((variant) => {
    return variant.size;
  });
};

const getProductVariants = ({ product }): ProductVariant[] => {
  return product.product_variants.map((variant) => {
    return variant;
  });
};

const getSelectedProduct = ({ product, size, color }): CartItem => {
  const selectedProductVariant = product.product_variants.find((variant) => {
    if (variant.size.name === size && variant.color.name === color) {
      return variant;
    }
  });

  //sets new field selected_variant_id and uid to the product which is placed in the cart
  product = {
    product: product,
    selected_variant: selectedProductVariant,
    uid: randomstring.generate(),
  };
  return product;
};

export type CartItemProduct = {
  product: BaseProduct;
  selected_variant: ProductVariant;
  uid: string;
};

const setOrderToCartItems = (
  OrderProductData: PartialOrderProductItemData[]
): CartItemProduct[] => {
  const productsWithVariants = OrderProductData.map((item) => {
    const product = item.product_variant?.product;
    const variant = item.product_variant;

    return {
      product: product,
      selected_variant: variant, // in an array for purposes of consistency in SimpleProductCard component
      uid: randomstring.generate(),
    };
  });
  return productsWithVariants;
};

const createProductList = (products) => {
  const sizesMappedByColor = {};
  for (let i = 0; i < products.length; i++) {
    sizesMappedByColor[products[i].color.name] = [...products];
  }
  return sizesMappedByColor;
};

const filterArrayForUniqueObjects = (array) => {
  return [...new Set(array.map((item) => JSON.stringify(item)))].map(
    (objects: any) => JSON.parse(objects)
  );
};

const getCartTotalValue = ({ cart }) => {
  const total = cart.reduce((acc, current) => {
    return acc + parseInt(current.product.rrp || 0);
  }, 0);
  return total;
};

/**
 * Gifting
 *
 */
const createCouponOrder = async ({
  status, //tbc,
  delivery_date,
  delivery_status,
  title_sender,
  first_name_sender,
  last_name_sender,
  email_sender,
  phone_sender,
  title_recipient,
  first_name_recipient,
  last_name_recipient,
  email_recipient,
  phone_recipient,
  message,
  coupon_value,
}) => {
  //format dates
  const momentDate = moment(delivery_date);
  const formattedDate = momentDate.format("dddd, MMMM Do YYYY, h:mm:ss a");

  //create order object
  const orderObject = {
    delivery_date: formattedDate,
    status, //tbc,
    delivery_status,
    title_sender,
    first_name_sender,
    last_name_sender,
    email_sender,
    phone_sender,
    title_recipient,
    first_name_recipient,
    last_name_recipient,
    email_recipient,
    phone_recipient,
    message,
    coupon_value,
  };

  //create order in DB
  //@ts-ignore
  const insertedOrder = await insertGiftingOrder(orderObject);
  const orderId = insertedOrder[0].id;

  return {
    message: "order place successfully",
    order_id: orderId,
  };
};

const getTagsAsFilter = (
  tags: Tag[] | VariantOption[],
  type?: TagTypes | VariantOptionTypes
): Filter[] =>
  (tags || [])
    .filter((tag) => typeof type === "undefined" || tag.type === type)
    .sort((tag1, tag2) => tag1.order - tag2.order)
    .map((tag) => ({
      name: tag.name,
      value: tag.slug,
      meta: tag?.metadata || {},
    }));

const getSizesWithStock = (tags: SizeOption[]): Filter[] =>
  tags
    .sort((tag1, tag2) => tag1.size.order - tag2.size.order)
    .map((tag) => ({
      name: tag.size.name,
      value: tag.size.slug,
      meta: tag?.size.metadata || {},
      stock: tag.stock,
    }));
/**
 *
 * @returns
 */
const checkPostcodeApproved = () => {
  const postcodeApproved =
    typeof window !== "undefined" && localStorage.getItem("postcode");

  return postcodeApproved;
};

export type LocalStock = {
  productsLocalStock: Product[];
  cartLocalStock: CartItem[];
};

/**
 * Generate the local stock adjusted items based on the cart & products
 */
const generateLocalStock = (
  cart: CartItem[],
  products: Product[],
  previousOrderProducts: CartItem[]
): LocalStock => {
  let productVariantLocalStock = {};
  // const newProduct = [..]

  //Add back the items from the current order as they will become available again when it gets returned
  (previousOrderProducts || []).forEach((prevProd) => {
    productVariantLocalStock[prevProd.selected_variant?.id] =
      productVariantLocalStock[prevProd.selected_variant?.id]
        ? productVariantLocalStock[prevProd.selected_variant?.id] - 1
        : -1; // Do Negative values as it's the negative impact of what a cart item has
  });

  // Adjust the current cart
  let adjustedCart = cart
    .filter((cartItem) => !!cartItem.product)
    .map((cartItem) => {
      const prod = products.find((prod) => prod.id === cartItem.product.id);
      const variant = prod?.product_variants.find(
        (variant) => variant.id === cartItem.selected_variant.id
      );

      if (!prod || !variant) {
        // getProductById;
        // Product or variant don't exist anymore
        return {
          ...cartItem,
          // unavailable: true, // Enable products that have been disabled in case of a return
        };
      }

      const newItem: CartItem = {
        // Update the item with the latest data
        ...cartItem,
        product: prod,
        selected_variant: variant,
        unavailable: false,
      };

      const cartStockImpact = productVariantLocalStock[
        cartItem.selected_variant.id
      ]
        ? productVariantLocalStock[cartItem.selected_variant.id] + 1
        : 1;

      productVariantLocalStock[cartItem.selected_variant.id] = cartStockImpact;

      if (cartStockImpact > variant.stock) {
        // Item is sold out as the stock in the cart is now higher then on the variant
        return {
          ...newItem,
          unavailable: true,
        };
      }

      return newItem;
    });

  //1.  Grab Stock from DB
  const adjustedProducts = products.map((prod) => {
    return {
      ...prod,
      product_variants: prod.product_variants.map((prodVar) => {
        // productVariantStocks[prodVar.id] = prodVar.stock;
        if (productVariantLocalStock[prodVar.id]) {
          return {
            ...prodVar,
            stock: prodVar.stock - productVariantLocalStock[prodVar.id],
          };
        } else {
          return prodVar;
        }
      }),
    };
  });

  return { productsLocalStock: adjustedProducts, cartLocalStock: adjustedCart };
};

export {
  getColorOptions,
  getSizeOptions,
  getSelectedProduct,
  getProductVariants,
  checkPostcodeApproved,
  getTagsAsFilter,
  createProductList,
  filterArrayForUniqueObjects,
  getCartTotalValue,
  setOrderToCartItems,
  getSizesWithStock,
  createCouponOrder,
  generateLocalStock,
};
